import React from "react";
import { Navigate, useParams } from "react-router-dom";
import ScrollToTop from "./ScrollTop";
import { ROUTES } from "../../../../config/routesConfig";
import { useRouting } from "../../../../hooks/routesHooks";
import { useLoginStatus } from "../../../../hooks/authHooks";

const RequireCustomerAuth = ({
    redirectRoute = ROUTES.TOKEN_AUTH_PAGE,
    scrollToTop = true,
    children,
}) => {
    const isLoggedIn = useLoginStatus();
    const { getRouteLink } = useRouting();
    const { projectId } = useParams();
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to home page

    if (!isLoggedIn) {
        return (
            <>
                {scrollToTop && <ScrollToTop />}{" "}
                <Navigate
                    to={getRouteLink(redirectRoute, { projectId })}
                    replace
                />
            </>
        );
    }

    return (
        <>
            {scrollToTop && <ScrollToTop />} {children}
        </>
    );
};

export default RequireCustomerAuth;
