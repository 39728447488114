import React, { useEffect, useRef, useState } from "react";
import "./landing.scss";
import Typography from "../../../components/Typography/Typography";

import { Icon } from "../../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import CarousalImageCard from "../../../components/Card/CarousalImageCard";
import { useLazyGetTraceAssetQuery } from "../../../services/supplyChainService";
import Button from "../../../components/Button/Button";

export default function LandingPage() {
    const navigate = useNavigate();

    const { projectId } = useParams();
    const [assetId, setAssetId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [hasTrace, setHasTrace] = useState(false);

    const [getTrace, results] = useLazyGetTraceAssetQuery();

    const [otp, setOtp] = useState(Array(6).fill(""));
    const otpBoxReference = useRef([]);
    const handleInputChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);
    };

    const handleCardClick = async () => {
        setIsLoading(true);

        try {
            const trace = await getTrace({
                id: projectId,
                asset_id: assetId,
            });

            if (trace.error) {
                throw "Error in trace";
            }

            if (assetId && assetId !== "") {
                navigate(`/app/${projectId}/trace/${assetId}`);
            }
            navigate(`/app/${projectId}/trace/${assetId}`);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if (value && index < 5) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpBoxReference.current[index - 1].focus();
        }
        if (e.key === "Enter" && e.target.value && index < 5) {
            otpBoxReference.current[index + 1].focus();
        }
    }

    return (
        <div className="container-fluid landing-container overflow-auto min-vh-100">
            <div className="landing-header d-flex align-items-center justify-content-center">
                <img
                    src="/images/serendipol-logo1.png"
                    alt="logo"
                    height={50}
                    width={108}
                />
            </div>
            {!hasTrace && (
                <>
                    <Typography
                        variant="display3"
                        className="text-center mt-5 pt-5"
                    >
                        Trace your product
                    </Typography>
                    <Typography variant="body1" className="text-center m-2">
                        Enter the 6 digit code mentioned in the back of the your
                        product to trace it.
                    </Typography>

                    <div className="items-center gap-2 d-flex align-items-center justify-content-center pt-4 w-sm-auto">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                value={digit}
                                maxLength={1}
                                onChange={(e) =>
                                    handleChange(e.target.value, index)
                                }
                                onKeyUp={(e) =>
                                    handleBackspaceAndEnter(e, index)
                                }
                                ref={(reference) =>
                                    (otpBoxReference.current[index] = reference)
                                }
                                className={`code-input border p-2 text-center rounded-md block focus:border-2 focus:outline-none appearance-none`}
                            />
                        ))}
                    </div>
                    <div className="m-4">
                        <Button
                            className="mt-2 primary"
                            onClick={handleCardClick}
                            isLoading={isLoading}
                        >
                            Trace it
                        </Button>
                    </div>

                    <div className="m-4 d-flex justify-content-center">
                        <img
                            src="/images/QR-image.png"
                            className="qr-code"
                            height={100}
                            width={129}
                            alt="qr-code"
                        />
                    </div>
                </>
            )}
            {hasTrace && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Typography variant="display3" className="text-center">
                        Your product made an impact!
                    </Typography>
                    <div className="my-4">
                        <img
                            src="/images/image-header.png"
                            className="product-image"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
