import React from "react";
import { Icon } from "../Icon";
import "../Card/card.scss";

export default function CarousalImageCard({ images, id }) {
    return (
        <div className="card carousal-card mx-2">
            <div id={id} className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {images.map((image, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target={`#${id}`}
                            data-bs-slide-to={index}
                            className={index === 0 ? "active" : ""}
                            aria-current={index === 0 ? "true" : "false"}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${
                                index === 0 ? "active" : ""
                            }`}
                        >
                            <img
                                src={image}
                                className="d-block w-100 carousel-image"
                                alt={`Slide ${index + 1}`}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="card-favorite-icon">
                <Icon src="/icons/favourite.svg" />
            </div>
        </div>
    );
}
