import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../../components/Typography/Typography";
import ProjectDashboardCard from "../../../../components/Card/ProjectDashboardCard";
import "./dashboardTab.scss";
import { BasicTable } from "../../../../components/tables";
import { useGetProjectDashboardQuery } from "../../../../services/taasService";
import { timeAgo } from "../../../../components/TimeAgo/timeAgo";
import LineChartComponent from "../../../../components/Chart/LineChart";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import { useModal } from "../../../../hooks/modalsHooks";

function DashboardTab() {
    const { id } = useParams();
    const { showModal } = useModal();
    const { hideModal } = useModal();
    const navigate = useNavigate();
    const {
        data: projectDashboardData,
        isLoading,
        error,
    } = useGetProjectDashboardQuery({
        projectId: id,
    });

    const graphData = projectDashboardData?.data?.graph_metadata.map(
        (item) => ({
            month: item.month_name.substring(0, 3), // get the first 3 characters of the month name
            fundraising: item.amount,
        })
    );

    const handleContinue = async () => {
        hideModal();
        navigate(`/overview/${id}/configuration`);
        window.location.reload();
    };

    const handleModalClose = () => {
        hideModal();
    };

    const tableData =
        projectDashboardData?.data?.payments?.slice(0, 5).map((payment) => ({
            date: timeAgo(payment.payment_date),
            backer: payment.email,
            amount: `${payment.currency.toUpperCase()} ${payment.amount}`,
        })) || [];

    useEffect(() => {
        if (
            error &&
            error?.data?.errors[0] === "Project must have a root asset"
        ) {
            showModal({
                modalType: "PopUpModal",
                modalProps: {
                    isOpen: true,
                    projectId: id,
                    type: "settings",
                    title: "Set up the project to view dashboard",
                    message:
                        "To access the dashboard, you need to set up your project. Click 'Continue' to start setting up your project and get access to your dashboard.",
                    handleContinue: handleContinue,
                    handleClose: handleModalClose,
                },
            });
        }
    }, [error]);

    if (isLoading)
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );

    return (
        <div className="row d-flex flex-column flex-md-row justify-content-center">
            <div className="col-12">
                <div className="my-2">
                    <Typography variant="h1" className="p-0">
                        Project Dashboard
                    </Typography>
                    <Typography variant="body2" className="p-0">
                        Manage and promote your project with real-time data
                    </Typography>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 mb-4">
                        <ProjectDashboardCard
                            name="Funding Raised"
                            value={projectDashboardData?.data?.total_funding}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <ProjectDashboardCard
                            name="Backers"
                            value={projectDashboardData?.data?.no_backers}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 pt-4">
                <div className="card chart-card">
                    <div className="card-body">
                        <Typography variant="h4" className="py-2">
                            Funding Progress
                        </Typography>
                        <LineChartComponent
                            data={graphData}
                            dataKey="fundraising"
                        />
                    </div>
                </div>
            </div>
            <div className="col-12 pt-4 pb-5">
                <Typography variant="h3" className="py-2">
                    Last 5 funders
                </Typography>
                <BasicTable
                    data={tableData}
                    columns={[
                        {
                            header: "Date",
                            accessor: "date",
                        },
                        {
                            header: "Backer",
                            accessor: "backer",
                        },
                        {
                            header: "Amount",
                            accessor: "amount",
                        },
                    ]}
                    isSortable={false}
                    isPaginated={tableData?.length > 5}
                    pageSize={4}
                    currentPage={0}
                    showCheckbox={false}
                />
            </div>
        </div>
    );
}

export default DashboardTab;
