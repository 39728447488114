import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Breadcrumbs from "./Breadcrumbs";
import { useLazyProfileQuery, useProfileQuery } from "../services/userService";
import { useProfile } from "../hooks/authHooks";

export default function CustomerLayout() {

  
    return (
        <>
            <div className="container-fluid">
                <main>
                    {/* //Todo: Add breadcrumbs */}
                    <Outlet />
                </main>
            </div>
        </>
    );
}
