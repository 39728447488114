import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';


export const projectAPI = createApi({
    reducerPath: 'projectAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Project'],
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: () => ({
                url: '/api/projects/',
            }),
            providesTags: ['Project'],
        }),
        getProjectDetail: builder.query({
            query: (id) => `/api/projects/${id}/`,
        }),
        createProject: builder.mutation({
            query: ({ name, template }) => ({
                url: '/api/projects/',
                method: 'POST',
                body: {
                    name,
                    template
                },
            }),
            invalidatesTags: ['Project'],
        }),
        //Get API to get the taas projects for the home dashboard page
        getTaasProjects: builder.query({
            query: () => ({
                url: '/api/tenants/all_project_dashboard/',
            }),
            providesTags: ['Project'],
        }),

    }),
});

export const { useGetProjectsQuery, useGetProjectDetailQuery, useCreateProjectMutation, useGetTaasProjectsQuery } = projectAPI;