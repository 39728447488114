import React, { useEffect, useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import { Form, Select } from "../../../../components/forms";
import {
    useCreateStripeAccountMutation,
    useGetConnectedAccountsQuery,
    useGetTokenizationQuery,
    useUpdateDefaultStripeAccountMutation,
} from "../../../../services/taasService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import Button from "../../../../components/Button/Button";
import Loading from "../../../../components/LoadingSkeleton/Loading";

const PaymentSetting = ({ id }) => {
    // State
    const [paymentProfiles, setPaymentProfiles] = useState([]);
    const [isDefaultProfileLoading, setDefaultProfileLoading] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState("");
    const [isCreateStripeLoading, setCreateStripeLoading] = useState(false);

    // Hooks
    const [createStripe] = useCreateStripeAccountMutation();
    const [updateDefaultStripeAccount] =
        useUpdateDefaultStripeAccountMutation();
    const navigate = useNavigate();

    // Queries
    const {
        data: connectedAccountResponse,
        isLoading: isLoadingConnectedAccount,
        refetch: refetchConnectedAccounts,
    } = useGetConnectedAccountsQuery();

    const {
        data: defaultAccountResponse,
        isLoading: isLoadingDefaultAccount,
        refetch: refetchDefaultAccounts,
    } = useGetTokenizationQuery({ projectId: id });

    // Effects
    useEffect(() => {
        if (connectedAccountResponse?.data) {
            const profiles = connectedAccountResponse.data
                .filter((profile) => profile.status) // Only include profiles with status === true
                .map((profile) => ({
                    value: profile.account,
                    label: profile.account,
                }));
            setPaymentProfiles(profiles);

            // If there is a default account, set it as the selected profile
            if (defaultAccountResponse?.data?.default_stripe_account) {
                setSelectedProfile(
                    defaultAccountResponse.data.default_stripe_account
                );
            }
        }
    }, [connectedAccountResponse, defaultAccountResponse]);

    // Handlers
    const handleCreateStripe = async () => {
        setCreateStripeLoading(true);
        try {
            const createStripeRes = await createStripe();
            if (createStripeRes.data) {
                toast("Stripe account created successfully");
                navigate("/settings/payments");
                logEvent({
                    eventName: "stripe_account_created",
                    eventProperties: {
                        account_id: createStripeRes.data.account.account,
                    },
                });
                refetchConnectedAccounts();
            } else {
                toast(createStripeRes.error.data?.errors[0]);
                logEvent({
                    eventName: "stripe_account_creation_failed",
                    eventProperties: {
                        error_message: createStripeRes.error.data?.errors[0],
                    },
                });
                throw new Error(
                    "Unexpected error during Stripe account creation"
                );
            }
        } catch (error) {
            console.error("Stripe account creation failed:", error);
            // toast(error.message);
        } finally {
            setCreateStripeLoading(false);
        }
    };

    const handleDefaultProfileButton = async (newProfile) => {
        setDefaultProfileLoading(true);
        try {
            const updateDefaultStripeAccountRes =
                await updateDefaultStripeAccount({
                    stripe_account: newProfile || selectedProfile,
                    projectId: id,
                });
            if (updateDefaultStripeAccountRes?.data) {
                toast("Default stripe account updated successfully");
                logEvent({
                    eventName: "default_stripe_account_updated",
                    eventProperties: {
                        account_id: newProfile || selectedProfile,
                    },
                });

                // Refresh the project details and the list of connected accounts
                refetchDefaultAccounts();
                refetchConnectedAccounts();
            } else {
                // toast("Default stripe account update failed");
                logEvent({
                    eventName: "default_stripe_account_update_failed",
                    eventProperties: {
                        account_id: selectedProfile,
                    },
                });
                toast(updateDefaultStripeAccountRes.error.data?.errors[0]);
                throw new Error(
                    "Unexpected error during default Stripe account update"
                );
            }
        } catch (error) {
            console.error("Default stripe account update failed:", error);
            // toast(error.message);
        } finally {
            setDefaultProfileLoading(false);
        }
    };

    // Loading state
    if (isLoadingConnectedAccount || isLoadingDefaultAccount) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    // Render
    return (
        <div>
            <Typography variant="h3" className="py-3">
                Payment Settings
            </Typography>
            <Form onSubmit={() => {}} enableReinitialize={true}>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-column align-items-end">
                            <Select
                                label="Default Payment Profile"
                                name="payment-profiles"
                                options={paymentProfiles}
                                value={selectedProfile}
                                onChange={(event) => {
                                    setSelectedProfile(event.target.value);
                                    handleDefaultProfileButton(
                                        event.target.value
                                    );
                                }}
                            />
                            {paymentProfiles.length === 0 &&
                                !isLoadingConnectedAccount && (
                                    <div className="error-message">
                                        <a href="/settings/payments">
                                            <Typography
                                                variant="body2"
                                                className="text-muted"
                                            >
                                                To set up default profile,
                                                stripe account must be completed
                                                first. Click here to complete
                                                the account.
                                            </Typography>
                                        </a>
                                    </div>
                                )}
                            <Button
                                type="button"
                                variant="secondary"
                                fullWidth={false}
                                className="ms-md-2 ms-0 mt-2 mt-md-2 text-overflow"
                                onClick={handleCreateStripe}
                            >
                                {isCreateStripeLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Loading...</span>
                                    </div>
                                ) : (
                                    <>
                                        <i className="bi bi-plus plus-icon"></i>
                                        Add Payment Profile
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default PaymentSetting;
