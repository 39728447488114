import React, { useState, useMemo, useEffect, useRef } from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Select, TextField } from "../../../../components/forms";
import * as Yup from "yup"; // Import Yup for validation
import {
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
    useGetOneAssetsTypeQuery,
    useUpdateAssetTypeMutation,
} from "../../../../services/supplyChainService";

import "./asset.scss";
import { string_to_snake_case } from "../../../../utils/projectUtils";
import { toast } from "react-toastify";
import { logEvent } from "../../../../utils/amplitudeUtlis";

const formConfigOptions = [
    {
        label: "Text Input",
        value: "text",
    },
    {
        label: "Date Input",
        value: "date",
    },
    {
        label: "Image Input",
        value: "image",
        name: "logo",
        // onFileSelected:{setFiles},
        // logoImage:{logoImage},
        // onChange:{onChange}
    },
];

function FormComponent({
    fieldName,
    fieldLabel,
    fieldType,
    fieldIndex,
    onRemoveField,
    onChangeProp,
    onDragStart,
    onDragOver,
    onDrop,
    isDragOver,
}) {
    // Handle the drag start event
    const handleDragStart = (e) => {
        onDragStart(e, fieldIndex);
    };

    // Handle the drag over event
    const handleDragOver = (e) => {
        e.preventDefault();
        onDragOver(e, fieldIndex);
    };

    // Handle the drop event
    const handleDrop = (e) => {
        onDrop(e, fieldIndex);
    };

    return (
        <div
            className={`add-container ${isDragOver ? "drag-over" : ""}`}
            key={fieldIndex}
            draggable
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div className="d-flex justify-content-between m-3 add-header">
                <div className="add-title">Form field - #{fieldIndex + 1}</div>
                <div className="d-flex align-items-center">
                    <div className="add-close" onClick={onRemoveField}>
                        X Remove
                    </div>
                    <div className="drag-icon">
                        <i className="bi bi-grip-vertical"></i>
                    </div>
                </div>
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_label_${fieldIndex}`}
                    label="Field Label"
                    value={fieldLabel}
                    onChange={(e) => onChangeProp("label", e.target.value)}
                    placeholder="Enter field label"
                />
            </div>
            <div className="m-3">
                <TextField
                    name={`form_config_name_${fieldIndex}`}
                    label="Field Name"
                    value={fieldName}
                    disabled={true}
                    onChange={(e) => onChangeProp("name", e.target.value)}
                />
            </div>
            <div className="m-3">
                <Select
                    options={formConfigOptions}
                    name={`form_config_type_${fieldIndex}`}
                    label="Field Type"
                    value={fieldType}
                    onChange={(e) => onChangeProp("type", e.target.value)}
                />
            </div>
        </div>
    );
}

export default function EditAssetPage() {
    const navigate = useNavigate();
    const { id, assetTypeId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [fieldsToValidate, setFieldsToValidate] = useState([]);
    const { data: selectedAssetTypeResponse } = useGetOneAssetsTypeQuery(
        { id, assetTypeId },
        { skip: !id }
    );
    const { data: assetTypesResponse } = useGetAssetsTypeQuery(id, {
        skip: !id,
    });

    const assetTypes = useMemo(() => {
        if (!assetTypesResponse?.data) {
            return [];
        }
        let atypes = [];
        for (var at of assetTypesResponse?.data) {
            if (at.id !== assetTypeId) {
                atypes.push({
                    label: at.type_name,
                    value: at.type_name,
                });
            }
        }
        return atypes;
    }, [assetTypesResponse]);

    const _formFields = useMemo(() => {
        if (!selectedAssetTypeResponse?.data?.form_config?.fields) {
            return [];
        }
        return selectedAssetTypeResponse?.data.form_config?.fields;
    }, [selectedAssetTypeResponse]);

    const [formFields, setFormFields] = useState([]);
    const dependencyConfig = selectedAssetTypeResponse?.data.dependency_config
        ?.dependencies
        ? selectedAssetTypeResponse?.data.dependency_config.dependencies[0]
        : null;

    const [dependency, setDependency] = useState(dependencyConfig?.type || "");
    const [updateAssetType] = useUpdateAssetTypeMutation();
    const [dragOverIndex, setDragOverIndex] = useState(null);

    useEffect(() => {
        setFormFields(_formFields);
    }, [_formFields]);

    useEffect(() => {
        setDependency(dependencyConfig?.type || "");
    }, [dependencyConfig]);

    useEffect(() => {
        // Function to handle the dragover event
        const handleDragOver = (e) => {
            const scrollMargin = 50;
            const scrollSpeed = 20;
            const { clientY } = e;

            if (clientY < scrollMargin) {
                window.scrollBy(0, -scrollSpeed);
            } else if (window.innerHeight - clientY < scrollMargin) {
                window.scrollBy(0, scrollSpeed);
            }
        };

        window.addEventListener("dragover", handleDragOver);

        return () => {
            window.removeEventListener("dragover", handleDragOver);
        };
    }, []);

    // Function to handle the close button click
    const handleCloseClick = () => {
        navigate(`/overview/${id}/`);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Edit Asset Page",
            },
        });
    };

    // Function to handle the assets submission of the form
    const handleAddAsset = async (values) => {
        setIsLoading(true);
        let _dependencyConfig = {
            dependencies: [],
        };
        if (dependency) {
            _dependencyConfig.dependencies.push({
                type: dependency,
                label: dependency,
            });
        }
        try {
            const updateAssetResponse = await updateAssetType({
                id: id,
                assetTypeId: assetTypeId,
                form_config: {
                    fields: formFields,
                },
                dependency_config: _dependencyConfig,
            });
            if (updateAssetResponse?.data.errors) {
                logEvent({
                    eventName: "record_type_update_error",
                    eventProperties: {
                        errorMessage: updateAssetResponse?.data.errors,
                    },
                });
                throw "Error in updating record type.";
            }
            logEvent({
                eventName: "record_type_updated",
                eventProperties: {
                    assetTypeId: assetTypeId,
                },
            });
            toast("Record type updated.");
            navigate(`/overview/${id}/configuration`);
        } catch (error) {
            toast("Error in updating record type");
            logEvent({
                eventName: "record_type_update_error",
                eventProperties: {
                    errorMessage: error,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle the addition of a form field
    const handleAddFormField = () => {
        const newFieldIndex = formFields.length;
        setFormFields([
            ...formFields,
            {
                label: "",
                name: "",
                type: "",
            },
        ]);
        setFieldsToValidate([...fieldsToValidate, newFieldIndex]);
    };

    // Function to handle the removal of a form field
    const handleRemoveFormField = (index) => {
        setFormFields(formFields.filter((_, i) => i !== index));
        setFieldsToValidate(fieldsToValidate.filter((i) => i !== index));
    };

    // Function to handle the change of a form field property
    const handleOnChangeFormFieldProp = (index, field, value) => {
        setFormFields(
            formFields.map((f, i) => {
                if (i !== index) {
                    return f;
                }
                if (field === "label") {
                    return {
                        ...f,
                        label: value,
                        name: string_to_snake_case(value || ""),
                    };
                } else {
                    return {
                        ...f,
                        [field]: value,
                    };
                }
            })
        );
    };

    // Function to handle the start of a drag event
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData("draggedIndex", index);
    };

    // Function to handle the drag over event on a field
    const handleDragOverField = (e, index) => {
        e.preventDefault();
        setDragOverIndex(index);
    };

    // Function to handle the drop event
    const handleDrop = (e, index) => {
        const draggedIndex = e.dataTransfer.getData("draggedIndex");
        if (draggedIndex === index) return;
        const updatedFormFields = [...formFields];
        const [draggedField] = updatedFormFields.splice(draggedIndex, 1);
        updatedFormFields.splice(index, 0, draggedField);
        setFormFields(updatedFormFields);
        setDragOverIndex(null);
    };

    // Function to generate initial values for the form
    const generateInitialValues = () => {
        let values = {
            type_name: selectedAssetTypeResponse?.data.type_name || "",
            dependency: dependency || "",
        };
        formFields.forEach((field, index) => {
            values[`form_config_label_${index}`] = field.label || "";
            values[`form_config_type_${index}`] = field.type || "";
        });
        return values;
    };

    // Validation schema for the form
    const validationSchema = Yup.object().shape({
        dependency: Yup.string().nullable(),
        ...fieldsToValidate.reduce((acc, idx) => {
            acc[`form_config_label_${idx}`] = Yup.string()
                .trim()
                .required("Field label is required")
                .min(1, "Field Label cannot be empty");
            acc[`form_config_type_${idx}`] = Yup.string()
                .required("Field type is required")
                .oneOf(
                    formConfigOptions.map((option) => option.value),
                    "Invalid field type"
                );
            return acc;
        }, {}),
    });

    return (
        <div className="project-container h-100 min-vh-100 w-100 px-4">
            <div className="pt-3">
                <Typography variant="body1">
                    Overview / Edit record type
                </Typography>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <Typography variant="h1">Edit record type</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="mt-4 divider-line"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-4">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <Form
                        initialValues={generateInitialValues()}
                        onSubmit={handleAddAsset}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                    >
                        <div className="m-3">
                            <TextField
                                name="type_name"
                                label="Record Type"
                                disabled={true}
                            />
                        </div>
                        <div className="m-3">
                            <Select
                                options={assetTypes}
                                name="dependency"
                                label="Linked Record Type (Keep empty if no links)"
                                value={dependency}
                                onChange={(e) => setDependency(e.target.value)}
                            />
                        </div>
                        <div className="m-3">
                            {formFields.map((ff, idx) => (
                                <FormComponent
                                    key={idx}
                                    fieldName={ff["name"]}
                                    fieldLabel={ff["label"]}
                                    fieldType={ff["type"]}
                                    fieldIndex={idx}
                                    onRemoveField={() =>
                                        handleRemoveFormField(idx)
                                    }
                                    onChangeProp={(field, value) =>
                                        handleOnChangeFormFieldProp(
                                            idx,
                                            field,
                                            value
                                        )
                                    }
                                    onDragOver={handleDragOverField}
                                    onDragStart={handleDragStart}
                                    onDrop={handleDrop}
                                    isDragOver={dragOverIndex === idx}
                                />
                            ))}
                        </div>
                        <div
                            className="m-3 add-another-node"
                            onClick={handleAddFormField}
                        >
                            + Add form field
                        </div>
                        <div className="m-3">
                            <Button
                                type="submit"
                                variant="primary"
                                fullWidth={false}
                                isLoading={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
