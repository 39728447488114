import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { BasicTable } from "../../../components/tables";
import { useModal } from "../../../hooks/modalsHooks";
import {
    useGetAllUsersQuery,
    useGetTenantQuery,
} from "../../../services/tenantService";
// import UserPageLoadingSkeleton from "../../../components/LoadingSkeleton/UserPageLoadingSkeleton";
import { Icon } from "../../../components/Icon";
import {
    useDeleteUserMutation,
    useResendInviteUserMutation,
} from "../../../services/userService";
import { toast } from "react-toastify";
import { logEvent } from "../../../utils/amplitudeUtlis";
import Loading from "../../../components/LoadingSkeleton/Loading";

export default function UserPage() {
    const { showModal } = useModal();
    const { data: tenantData, isLoading } = useGetTenantQuery();
    const [resendInvite] = useResendInviteUserMutation();
    const [deleteUser] = useDeleteUserMutation();
    const tenantId = tenantData?.data?.owner;
    const { data: users, refetch } = useGetAllUsersQuery();
    const userData = users?.data;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        refetch().then(() => {
            setLoading(false);
        });
    }, [refetch, userData]);

    const handleResendOnClick = async (id) => {
        setLoading(true);
        try {
            const response = await resendInvite({
                id,
            });
            toast("Invitation has been sent again");

            if (response.error) {
                toast(response.error.data.errors[0], { type: "error" });
                logEvent({
                    eventName: "resend_invite_failed",
                    eventProperties: {
                        user_id: id,
                    },
                });
                throw new Error("Error completing registration");
            }
            setLoading(false);
            logEvent({
                eventName: "resend_invite_successful",
                eventProperties: {
                    user_id: id,
                },
            });
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleDeleteOnClick = async (id) => {
        setLoading(true);
        try {
            const response = await deleteUser({
                id,
            });
            logEvent({
                eventName: "invited_user_deleted_successfully",
                eventProperties: {
                    user_id: id,
                },
            });
            if (response.error) {
                toast(response.error.data.errors[0], { type: "error" });
                logEvent({
                    eventName: "invited_user_delete_failed",
                    eventProperties: {
                        user_id: id,
                    },
                });
                throw new Error("Error completing registration");
            }

            toast("Deleted Successfully");
            refetch().then(() => {
                setLoading(false);
            });
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    const handleInviteUser = async () => {
        logEvent({
            eventName: "user_invite_initiated",
            eventProperties: {
                tenant_id: tenantId,
            },
        });
        showModal({
            modalType: "InviteUserModal",
            modalProps: { tenantId: tenantId, refetch, setLoading },
        });
    };

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <Typography
                        className="ps-md-4 ps-0 text-md-start text-center mb-md-0"
                        variant="h1"
                    >
                        All users
                    </Typography>

                    <div className="me-0 me-md-4 mb-2 mb-md-0 p-md-0 p-3 pt-3">
                        <Button
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            onClick={handleInviteUser}
                        >
                            Invite user
                        </Button>
                    </div>
                </div>
                <div className="m-4 mb-5 divider-line"></div>
            </div>

            <div className="mx-3 row d-flex flex-wrap">
                <div className="col-12 col-md-12">
                    {!loading && userData && userData.length > 0 ? (
                        <BasicTable
                            data={userData}
                            columns={[
                                {
                                    header: "Name",
                                    accessor: "first_name" + "last_name",
                                    Cell: ({ row }) =>
                                        row.original.first_name &&
                                        row.original.last_name ? (
                                            <div>
                                                {row.original.first_name +
                                                    " " +
                                                    row.original.last_name}
                                            </div>
                                        ) : (
                                            <div>Unknown</div>
                                        ),
                                },
                                {
                                    header: "Email",
                                    accessor: "username",
                                },
                                {
                                    header: "Role",
                                    accessor: "role",
                                    Cell: ({ row }) => (
                                        <div>
                                            {row.original.role
                                                .charAt(0)
                                                .toUpperCase() +
                                                row.original.role
                                                    .slice(1)
                                                    .toLowerCase()}
                                        </div>
                                    ),
                                },
                                {
                                    header: "Status",
                                    accessor: "reg_status",
                                    Cell: ({ row }) => (
                                        <div>
                                            {row.original.reg_status
                                                .charAt(0)
                                                .toUpperCase() +
                                                row.original.reg_status
                                                    .slice(1)
                                                    .toLowerCase()}
                                        </div>
                                    ),
                                },
                                {
                                    header: "Date Created",
                                    accessor: "created_at",
                                    Cell: ({ value }) => {
                                        const date = new Date(value);
                                        const day = String(
                                            date.getDate()
                                        ).padStart(2, "0");
                                        const month = String(
                                            date.getMonth() + 1
                                        ).padStart(2, "0"); // January is 0!
                                        const year = date.getFullYear();
                                        return `${day}-${month}-${year}`;
                                    },
                                },
                                {
                                    header: "",
                                    accessor: "action",
                                    Cell: ({ row }) => (
                                        <div className="d-flex flex-row justify-content-end">
                                            {row.original.reg_status ===
                                                "INVITED" && (
                                                <>
                                                    <Icon
                                                        src="/icons/resend.svg"
                                                        className="mx-2"
                                                        onClick={() => {
                                                            handleResendOnClick(
                                                                row.original.id
                                                            );
                                                        }}
                                                    />
                                                    <Icon
                                                        src="/icons/delete.svg"
                                                        className="mx-2"
                                                        onClick={() => {
                                                            handleDeleteOnClick(
                                                                row.original.id
                                                            );
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ),
                                },
                            ]}
                            isSortable={false}
                            isPaginated={userData && userData.length >= 8}
                            pageSize={8}
                            currentPage={0}
                            showCheckbox={false}
                        />
                    ) : (
                        <div>
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ height: "100vh" }}
                            >
                                <Loading />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
