import { useState } from "react";
import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";
import ModalLayout from "../modals/ModalLayout";
import "./model.scss";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../utils/amplitudeUtlis";
/**
 * Example modal component. Shown via `ModalContext`
 * @param {{
 * title: string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function CreateProjectModal({
    title = "Add new project",
    projectType = "supply-chain",
}) {
    const { hideModal } = useModal();
    const [selectedCard, setSelectedCard] = useState(projectType);
    const navigate = useNavigate();
    const handleClose = () => {
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Create Project Modal Page",
            },
        });
        hideModal();
    };

    const handleCardClick = (cardName) => {
        setSelectedCard(cardName);
    };

    const handleSelectAndContinue = () => {
        logEvent({
            eventName: "selected_type_of_the_project",
            eventProperties: {
                project_type: selectedCard,
            },
        });
        hideModal();
        navigate(`/start/${selectedCard}`);
    };

    return (
        <ModalLayout width="80%" className="overflow-auto">
            <div className="card d-flex justify-content-center p-4">
                <ModalLayout.Header>
                    <Typography variant="h2">{title}</Typography>
                    <Typography variant="body1">
                        Select the usecase below based on your new project
                        requirements
                    </Typography>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="row d-flex flex-wrap">
                        <div className="col-12 col-md-3 d-flex flex-wrap mb-4 mb-md-0">
                            <div
                                className={`card carding p-2 shadow-none ${
                                    selectedCard === "token-service"
                                        ? "selected-card"
                                        : ""
                                }`}
                                onClick={() => handleCardClick("token-service")}
                            >
                                <div className="card-image-container">
                                    {selectedCard === "token-service" && (
                                        <img
                                            src="/icons/tick-icon.svg"
                                            alt="tick"
                                            className="tick-icon"
                                        />
                                    )}
                                    <img
                                        src="/images/ticketing.svg"
                                        className="p-2 card-img-top rounded"
                                        alt="..."
                                        height={150}
                                    />
                                </div>
                                <div className="card-body p-2">
                                    <div className="card-title">
                                        <Typography variant="h4">
                                            Tokenization as a Service
                                        </Typography>
                                    </div>
                                    <div className="card-text">
                                        <Typography variant="body1">
                                            Create digital assets of physical
                                            goods and services
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 d-flex flex-wrap mb-4 mb-md-0">
                            <div
                                className={`card carding p-2 shadow-none ${
                                    selectedCard === "supply-chain"
                                        ? "selected-card"
                                        : ""
                                }`}
                                onClick={() => handleCardClick("supply-chain")}
                            >
                                <div className="card-image-container">
                                    {selectedCard === "supply-chain" && (
                                        <img
                                            src="/icons/tick-icon.svg"
                                            alt="tick"
                                            className="tick-icon"
                                        />
                                    )}
                                    <img
                                        src="/images/supplyChain.svg"
                                        className="p-2 card-img-top rounded"
                                        alt="..."
                                        height={150}
                                    />
                                </div>

                                <div className="card-body p-2">
                                    <div className="card-title">
                                        <Typography variant="h4">
                                            Supply Chain Traceability
                                        </Typography>
                                    </div>
                                    <div className="card-text">
                                        <Typography variant="body1">
                                            Implement tracebility for a supply
                                            chain
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 d-flex flex-wrap mb-4 mb-md-0 disabled">
                            <div
                                className={`card carding p-2 shadow-none ${
                                    selectedCard === "Fund raise"
                                        ? "selected-card"
                                        : ""
                                }`}
                                // onClick={() => handleCardClick("Fund raise")}
                            >
                                <div className="card-image-container">
                                    {selectedCard === "Fund raise" && (
                                        <img
                                            src="/icons/tick-icon.svg"
                                            alt="tick"
                                            className="tick-icon"
                                        />
                                    )}
                                    <img
                                        src="/images/fund_raise.svg"
                                        className="p-2 card-img-top rounded card-image"
                                        alt="..."
                                        height={150}
                                    />
                                </div>
                                <div className="card-body p-2">
                                    <div className="card-title">
                                        <Typography variant="h4">
                                            Fundraise
                                        </Typography>
                                    </div>
                                    <div className="card-text">
                                        <Typography variant="body1">
                                            Raise funds for a project using
                                            tokenized assets
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 d-flex flex-wrap mb-4 mb-md-0 disabled">
                            <div
                                className={`card carding p-2 shadow-none ${
                                    selectedCard === "Loyalty/membership"
                                        ? "selected-card"
                                        : ""
                                }`}
                                // onClick={() =>
                                //     handleCardClick("Loyalty/membership")
                                // }
                            >
                                <div className="card-image-container">
                                    {selectedCard === "Loyalty/membership" && (
                                        <img
                                            src="/icons/tick-icon.svg"
                                            alt="tick"
                                            className="tick-icon"
                                        />
                                    )}
                                    <img
                                        src="/images/loyalty.svg"
                                        className="p-2 card-img-top rounded"
                                        alt="..."
                                        height={150}
                                    />
                                </div>
                                <div className="card-body p-2">
                                    <div className="card-title">
                                        <Typography variant="h4">
                                            Loyalty / Membership
                                        </Typography>
                                    </div>
                                    <div className="card-text">
                                        <Typography variant="body1">
                                            Use tokens to create loyalty
                                            programs
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="mt-4 d-flex justify-content-center flex-row align-items-center">
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-secondary me-2"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-primary"
                            onClick={handleSelectAndContinue}
                        >
                            Select and continue
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
