import React, { useRef } from "react";
import { useModal } from "../../hooks/modalsHooks";
import Typography from "../Typography/Typography";
import "./model.scss";
import { useNavigate } from "react-router-dom";
import ModalLayout from "../modals/ModalLayout";
import QRCode from "react-qr-code";
import { logEvent } from "../../utils/amplitudeUtlis";

export default function TraceAssetModal({ assetLink }) {
    const { hideModal } = useModal();
    const navigate = useNavigate();
    const qrCodeRef = useRef(null);

    const qrLink = `${window.location.origin}${assetLink}`;

    const handleClose = () => {
        hideModal();
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Trace Asset Modal Page",
            },
        });
    };

    const handleViewTrace = () => {
        navigate(assetLink);
        hideModal();
    };

    const handleDownloadQR = () => {
        const assetId = assetLink.split("/")[2];
        const svg = qrCodeRef.current.querySelector("svg");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngFile;
            downloadLink.download = `trace-${assetId}.png`;
            downloadLink.click();
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    };

    return (
        <ModalLayout width="100%" className="overflow-auto">
            <div className="card d-flex justify-content-center p-4">
                <ModalLayout.Header>
                    <Typography  variant="h2">View record trace</Typography>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="mt-4 d-flex justify-content-center flex-row align-items-center gap-2">
                        <div ref={qrCodeRef}>
                            <QRCode value={qrLink} size={256} />
                        </div>
                        <div className="mt-2 d-flex justify-content-center"></div>
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="mt-4 d-flex justify-content-center flex-row align-items-center gap-2">
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleDownloadQR}
                        >
                            Download QR
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={handleViewTrace}
                        >
                            View trace
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary me-2"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}