import React, { useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { BasicTable } from "../../../components/tables";
import {
    useCreateCheckoutSessionMutation,
    useGetBillingQuery,
    useGetPaymentHistoryQuery,
} from "../../../services/billingService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetTenantQuery } from "../../../services/tenantService";
import { logEvent } from "../../../utils/amplitudeUtlis";
import Loading from "../../../components/LoadingSkeleton/Loading";

export default function BillingPage() {
    const [createCheckoutSession] = useCreateCheckoutSessionMutation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { data: paymentHistory } = useGetPaymentHistoryQuery();
    const paymentData = paymentHistory?.data;
    const { data: tenantData } = useGetTenantQuery();
    const { data: billingData } = useGetBillingQuery();
    const currentTier = tenantData?.data?.current_tier;
    const availableCredits = tenantData?.data?.available_credits;
    const isTierActive = tenantData?.data?.is_tier_active;
    const formattedCurrentTier = currentTier?.split("_").join(" ");

    if (isLoading || !paymentData || !tenantData) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    //Button function for change plan
    const handleChangePlanButton = async () => {
        try {
            const checkoutAsProTier = await createCheckoutSession({
                product_id: "PRO_TIER",
            });
            if (checkoutAsProTier.data) {
                logEvent({
                    eventName: "change_plan_initiated",
                    eventProperties: {
                        plan: "PRO_TIER",
                    },
                });
                const checkoutUrl = checkoutAsProTier.data?.data?.url;

                window.open(checkoutUrl, "_blank");
            } else {
                toast(billingData?.error?.data?.errors[0]);
                logEvent({
                    eventName: "change_plan_failed",
                    eventProperties: {
                        plan: "PRO_TIER",
                    },
                });
            }
        } catch (error) {}
    };

    //Button function for billing if admin updated as the PRO-TIER only it will be shown
    const handleBillingButton = async () => {
        setIsLoading(true);
        try {
            if (billingData?.data) {
                setIsLoading(true);
                const checkoutUrl = billingData.data?.customer_portal_url;
                window.open(checkoutUrl, "_blank");
                logEvent({
                    eventName: "billing_portal_opened",
                    eventProperties: {
                        plan: "PRO_TIER",
                    },
                });
            } else {
                toast(billingData?.error?.data?.errors[0]);
                logEvent({
                    eventName: "billing_portal_failed",
                    eventProperties: {
                        plan: "PRO_TIER",
                    },
                });
            }
            setIsLoading(false);
        } catch (error) {}
    };

    //Button function for top up credits
    const handleTopUpCreditButton = async () => {
        setIsLoading(true);
        try {
            const topUpCreditsRes = await createCheckoutSession({
                product_id: "STANDARD_CREDITS_TOPUP",
            });

            if (topUpCreditsRes.data) {
                logEvent({
                    eventName: "top_up_credits_initiated",
                    eventProperties: {
                        plan: "STANDARD_CREDITS_TOPUP",
                    },
                });
                setIsLoading(true);
                const checkoutUrl = topUpCreditsRes.data?.data?.url;
                window.open(checkoutUrl, "_blank");
            } else {
                toast(topUpCreditsRes?.error?.data?.errors[0]);
                logEvent({
                    eventName: "top_up_credits_failed",
                    eventProperties: {
                        plan: "STANDARD_CREDITS_TOPUP",
                    },
                });
            }
            setIsLoading(false);
        } catch (error) {}
    };

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <Typography
                        className="ps-md-4 ps-0 text-md-start text-center mb-md-0"
                        variant="h1"
                    >
                        Billing
                    </Typography>
                </div>
                <div className="m-4 mb-2 divider-line"></div>
            </div>

            <div className="mx-3 row d-flex flex-wrap">
                <div className="col-12 col-md-6 py-4 d-flex flex-column justify-content-md-start align-items-center align-items-md-start text-center text-md-start">
                    <div className="m-1">
                        <Typography variant="h2">Credit Usage</Typography>
                    </div>
                    <div className="m-1">
                        <Typography variant="body1">
                            {availableCredits} credits remaining
                        </Typography>
                    </div>
                    <div className="m-1">
                        <Button
                            variant="outline-primary"
                            fullWidth={false}
                            onClick={handleTopUpCreditButton}
                        >
                            Top Up Credits
                        </Button>
                    </div>
                </div>
                <div className="col-12 col-md-6 py-4 d-flex flex-column justify-content-md-start align-items-center align-items-md-start text-center text-md-start">
                    <div className="m-1">
                        <Typography variant="h2">Subscription</Typography>
                    </div>
                    <div className="m-1">
                        <Typography variant="body1">
                            Current Subscription :{" "}
                            <strong>{formattedCurrentTier}</strong>
                        </Typography>
                    </div>
                    {!isTierActive && (
                        <div className="m-1">
                            <Typography variant="body1" color="error">
                                Your subscription has expired.
                            </Typography>
                        </div>
                    )}
                    <div className="m-1 d-flex ">
                        <div className>
                            <Button
                                variant="outline-primary"
                                fullWidth={false}
                                onClick={() =>
                                    window.open(
                                        "https://hyperglade.com/",
                                        "_blank"
                                    )
                                }
                            >
                                View Plans
                            </Button>
                        </div>
                        <div className="ms-2">
                            {currentTier === "FREE_TIER" ? (
                                <Button
                                    variant="outline-primary"
                                    fullWidth={false}
                                    onClick={handleChangePlanButton}
                                >
                                    Change plans
                                </Button>
                            ) : (
                                currentTier === "PRO_TIER" && (
                                    <Button
                                        variant="outline-primary"
                                        fullWidth={false}
                                        onClick={
                                            isTierActive
                                                ? handleBillingButton
                                                : handleChangePlanButton
                                        }
                                    >
                                        {isTierActive ? "Billing" : "Renew"}
                                    </Button>
                                )
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-12 pt-3">
                    <div>
                        <Typography variant="h3">Invoices</Typography>
                    </div>
                    <BasicTable
                        data={paymentData}
                        columns={[
                            {
                                header: "Date",
                                accessor: "created",
                            },
                            {
                                header: "Amount",
                                accessor: "amount",
                            },
                            {
                                header: "Status",
                                accessor: "status",
                                Cell: ({ value }) => (
                                    <div>
                                        {value.charAt(0).toUpperCase() +
                                            value.slice(1)}
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={paymentData?.length > 8}
                        pageSize={8}
                        currentPage={0}
                        showCheckbox={false}
                    />
                </div>
            </div>
        </div>
    );
}
