import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';


export const billingAPI = createApi({
    reducerPath: 'billingAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['PaymentHistory', 'CheckoutSession', 'Billing'],
    endpoints: (builder) => ({
        getPaymentHistory: builder.query({
            query: () => ({
                url: '/api/store/payment-history/',
            }),
            providesTags: ['PaymentHistory'],
        }),
        createCheckoutSession: builder.mutation({
            query: ({ product_id }) => ({
                url: '/api/store/checkout/',
                method: 'POST',
                body: {
                    product_id
                },
            }),
            invalidatesTags: ['CheckoutSession'],
        }),
        getBilling: builder.query({
            query: () => ({
                url: '/api/store/billing-portal/',
            }),
            provideTags: ['Billing'],
        }),
    }),
});

export const { useGetPaymentHistoryQuery, useCreateCheckoutSessionMutation, useGetBillingQuery } = billingAPI;