import React, { useState } from "react";
import Button from "../Button/Button";
import "../forms/form.scss";
import Form from "./Form";
import TextField from "./TextField";
import TextArea from "./TextArea";

export default function AddForm({
    title,
    type,
    count,
    initialValues,
    onSubmit,
    isLoading = false,
    selectOptions = [],
    validationSchema,
}) {
    const [forms, setForms] = useState([{}]); // Initialize with one form

    const addForm = () => setForms([...forms, {}]); // Add a new form

    const removeForm = (index) => {
        setForms(forms.filter((_, i) => i !== index)); // Remove the form at the given index
    };

    return (
        <div>
            {forms.map((_, index) => (
                <div key={index} className="add-container">
                    <div className="d-flex justify-content-between m-3 add-header">
                        <div className="add-title">
                            {title} # {count}
                        </div>
                        <div
                            className="add-close"
                            onClick={() => removeForm(index)}
                        >
                            X Remove
                        </div>
                    </div>
                    <div>
                        <Form
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                        >
                            {type === "node" && (
                                <div>
                                    <div className="m-3">
                                        <TextField
                                            name="nodeTitle"
                                            label="Node title"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <TextArea
                                            name="description"
                                            label="Descriptions"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <TextField
                                            name="location"
                                            label="Location"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <TextField
                                            name="promoImageURL"
                                            label="Promo Image URL"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            fullWidth={false}
                                            isLoading={isLoading}
                                        >
                                            Add Node
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {type === "asset" && (
                                <div>
                                    <div className="m-3">
                                        <TextField
                                            name="assetTitle"
                                            label="Record type name"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            fullWidth={false}
                                            isLoading={isLoading}
                                        >
                                            Add Record Type
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            ))}
            <div className="m-2 mb-4 add-another-node" onClick={addForm}>
                + Add another {type === "asset" ? "Asset" : "Node"}
            </div>
        </div>
    );
}
