// Desc: Configuration tab for the project page

import React from "react";
import ConfigureSupplyChainTraceabilityProject from "../../sct/ConfigurationTab/ConfigureSupplyChainTraceabilityProject";
import ConfigureTokenizationProject from "../../taas/Admin/ConfigurationTab/ConfigureTokenizationProject";
import { ProjectTemplates } from "../../../utils/projectUtils";

export default function ConfigurationTab({ projectType }) {
    if (projectType === ProjectTemplates.SUPPLY_CHAIN) {
        return <ConfigureSupplyChainTraceabilityProject />;
    }

    if (projectType === ProjectTemplates.TOKENIZATION) {
        return <ConfigureTokenizationProject />;
    }
}
