import { useNavigate, useParams } from "react-router-dom";
import DashboardCard from "../../../../components/taas/customer/Card/DashboardCard";

const TabComponent = ({ status, purchaseRecordResponse }) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const filteredRecords = purchaseRecordResponse?.payment_transactions.filter(
        (record) => record.status === status
    );
    const handleCardClick = (paymentId) => {
        navigate(`/token-app/${projectId}/${paymentId}`);
    };
    return (
        <div>
            {filteredRecords?.length > 0 ? (
                filteredRecords?.map((record) => (
                    <div
                        className="row d-flex flex-wrap"
                        key={record.payment_id}
                    >
                        <div className="col-12 d-flex flex-wrap">
                            <DashboardCard
                                image={record.data?.tokenized_asset?.image}
                                title={
                                    record.data?.tokenized_asset?.name ||
                                    "Funding phase 01"
                                }
                                date={new Date(
                                    record.payment_date
                                ).toLocaleDateString("en-US", {
                                    month: "short",
                                    day: "numeric",
                                    year: "numeric",
                                })}
                                investment={`$${record.payment_amount.toFixed(
                                    2
                                )}`}
                                amount={`$${record.payment_amount.toFixed(2)}`}
                                onClick={() =>
                                    handleCardClick(record.payment_id)
                                }
                            />
                        </div>
                    </div>
                ))
            ) : (
                <div>No details available</div>
            )}
        </div>
    );
};

export default TabComponent;
