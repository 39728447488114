import React from "react";
import "./integrationItem.scss";

const IntegrationItem = ({
    imageUrl,
    title,
    subtitle,
    dateAdded,
    onDelete,
}) => {
    return (
        <div className="row mt-2">
            <div className="col-12 d-flex">
                <div className="d-flex align-items-center">
                    <img src={imageUrl} alt="icon" className="image-icon" />
                </div>
                <div className="col-6 col-md-8">
                    <div className="integration-body ps-2 pt-2">
                        <div className="integration-card-title">{title}</div>
                        <p className="integration-card-subtitle">
                            <small className="">{subtitle}</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationItem;
