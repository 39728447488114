// File: taas/overview/Summary.jsx

import React from "react";
import ProjectDashboardCard from "../../../../components/Card/ProjectDashboardCard";

const TaasSummary = ({ data }) => {
    return (
        <div className="row d-flex flex-wrap mt-4">
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                <ProjectDashboardCard
                    name="Total Projects"
                    value={data?.total_projects}
                    variant="main"
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                <ProjectDashboardCard
                    name="Total Funded"
                    value={`$${data?.total_funding}`}
                    variant="main"
                />
            </div>
            <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap">
                <ProjectDashboardCard
                    name="Total Backers"
                    value={data?.total_backers}
                    variant="main"
                />
            </div>
        </div>
    );
};

export default TaasSummary;
