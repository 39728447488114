import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDisconnect } from "@thirdweb-dev/react";

export default function TaasSignoutPage() {
    const disconnect = useDisconnect();
    const { projectId } = useParams();

    useEffect(() => {
        walletLogout();
    }, []);

    const walletLogout = async () => {
        try {
            await disconnect();
        } catch (error) {
            console.error("Error in disconnecting wallet", error);
        }
        localStorage.clear();
        sessionStorage.clear();
        window.location = `/token-app/${projectId}/login`;
    };

    return null;
}
