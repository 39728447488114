import React, { useState } from "react";

import {
    Checkbox,
    FileField,
    Select,
    TextField,
} from "../../../components/forms";
import TextArea from "../../../components/forms/TextArea";
import { useGetAssetsListQuery } from "../../../services/supplyChainService";
import { useParams } from "react-router-dom";
import ImageUpload from "../../../components/forms/ImageUpload";

const RecordForm = ({
    fieldType,
    name,
    label,
    options = [],
    typeName = null,
    ...props
}) => {
    const { id } = useParams();

    const { data: assetResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({ projectId: id });

    const [files, setFiles] = useState([]);
    const [logoImage, setLogoImage] = useState(null);

    const onFileSelected = (file) => {
        console.log(file, "File");
        setFiles([file]);
        setLogoImage(file);
    };

    const renderField = () => {
        switch (fieldType) {
            case "text":
                return <TextField name={name} label={label} />;
            case "number":
                return <TextField name={name} label={label} type="number" />;
            case "date":
                return <TextField name={name} label={label} type="date" />;
            case "dropdown":
                return (
                    <Select
                        name={name}
                        label={label}
                        options={options}
                        {...props}
                    />
                );
            case "image":
                return (
                    <ImageUpload
                        name={name}
                        label={label}
                        onFileSelected={setFiles}
                        logoImage={logoImage}
                        onChange={onFileSelected}
                        width="100%"
                        multiple={true}
                    />
                );
            case "file":
                return <FileField name={name} label={label} />;
            case "checkbox":
                return <Checkbox name={name} label={label} />;
            case "description":
                return <TextArea name={name} label={label} />;
            case "dependency":
                /**TODO: Change this to call the filter endpoint and get relevant assets */
                console.log(assetResponse?.data, "Asset response");
                const filteredAssets = assetResponse?.data
                    .filter((asset) => asset.asset_type.type_name === typeName)
                    .map((asset) => ({
                        value: asset.asset_id,
                        label: asset.name,
                    }));

                console.log(filteredAssets, "Filtered Assets");
                if (filteredAssets.length > 0) {
                    return (
                        <Select
                            name={name}
                            label={label}
                            options={filteredAssets}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }

            default:
                return <TextField name={name} label={label} />;
        }
    };

    return <div className="my-4">{renderField()}</div>;
};

export default RecordForm;
