// File: taas/overview/ProjectList.jsx

import React from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import { BasicTable } from "../../../../components/tables";

const ProjectList = ({
    isLoading,
    tableData,
    handleOnViewProjectLinkClick,
    handleCreateNewProject,
}) => {
    return (
        <div className="row mt-4">
            <div className="col-12">
                {isLoading ? (
                    <Loading />
                ) : tableData?.length > 0 ? (
                    <BasicTable
                        data={tableData}
                        columns={[
                            {
                                header: "Project",
                                accessor: "project",
                            },
                            {
                                header: "Amount",
                                accessor: "amount",
                            },
                            {
                                header: "Backers",
                                accessor: "backer",
                            },
                            {
                                header: "Status",
                                accessor: "status",
                                Cell: ({ value }) => {
                                    let statusClass;
                                    switch (value) {
                                        case "Live":
                                            statusClass = "status-live";
                                            break;
                                        case "Not Live":
                                            statusClass = "status-not-live";
                                            break;
                                        case "Stopped":
                                            statusClass = "status-stopped";
                                            break;
                                        default:
                                            statusClass = "";
                                    }
                                    return (
                                        <div className="status-component">
                                            <span className={statusClass}>
                                                {value}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            {
                                header: "Actions",
                                accessor: "actions",
                                Cell: ({ row: { original } }) => (
                                    <div
                                        className="link-button"
                                        onClick={() =>
                                            handleOnViewProjectLinkClick(
                                                original.id
                                            )
                                        }
                                    >
                                        View
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={tableData?.length > 5}
                        pageSize={5}
                        currentPage={0}
                        showCheckbox={false}
                    />
                ) : (
                    <div className="d-flex flex-column align-items-center text-center pt-3">
                        <img
                            src="/images/placeholderImage.svg"
                            alt="Placeholder"
                        />
                        <Typography variant="h3" className="pt-3">
                            Start Your First Project
                        </Typography>
                        <Typography variant="body1" className="pt-2">
                            You haven't started any projects yet. Click the
                            button below to start your first trade finance
                            project.
                        </Typography>
                        <Button
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCreateNewProject}
                        >
                            Start Project
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectList;
