import React, { useEffect, useState, useMemo } from "react";
import { BasicTable } from "../../../components/tables";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import {
    useCreateStripeAccountMutation,
    useGetConnectedAccountsQuery,
    useGetExternalLoginQuery,
} from "../../../services/taasService";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "./payment.scss";
import { logEvent } from "../../../utils/amplitudeUtlis";
import Loading from "../../../components/LoadingSkeleton/Loading";

export default function PaymentsPage() {
    const [createStripe] = useCreateStripeAccountMutation();
    const [accountId, setAccountId] = useState(null);
    const [isCreateStripeLoading, setCreateStripeLoading] = useState(false);
    const location = useLocation();
    const queryParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );

    const {
        data: connectedAccountResponse,
        isLoading: isLoadingConnectedAccount,
        refetch: refetchConnectedAccounts,
    } = useGetConnectedAccountsQuery();

    const { data: externalLoginResponse, isLoading: isLoadingExternalLogin } =
        useGetExternalLoginQuery(accountId);

    const connectedAccountList = useMemo(
        () => connectedAccountResponse?.data || [],
        [connectedAccountResponse]
    );
    const paymentData =
        connectedAccountList?.map((payment) => {
            return {
                account_id: payment.account,
                created: new Date(payment.created_at).toLocaleDateString(
                    "en-US",
                    {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    }
                ),
                status: payment.status ? "Active" : "Inactive",
                hasPayments: payment.charges_enabled ? "Yes" : "No",
                hasCharges: payment.charges_enabled ? "Yes" : "No",
                details_submitted: payment.details_submitted ? "Yes" : "No",
                action: "--",
            };
        }) || [];

    const handleUpdateProfileButton = (accountId) => {
        setAccountId(accountId);
        logEvent({
            eventName: "stripe_profile_update",
            eventProperties: {
                account_id: accountId,
            },
        });
    };

    const handleCreateStripe = async () => {
        setCreateStripeLoading(true);
        try {
            const createStripeRes = await createStripe();
            if (createStripeRes?.data) {
                toast("Stripe account created successfully");
                refetchConnectedAccounts();
                logEvent({
                    eventName: "stripe_account_created",
                    eventProperties: {
                        account_id:
                            createStripeRes?.data?.data?.account?.account,
                    },
                });
            } else {
                toast(createStripeRes.error.data?.errors[0]);
                logEvent({
                    eventName: "stripe_account_creation_failed",
                    eventProperties: {
                        error_message: createStripeRes.error.data?.errors[0],
                    },
                });
            }
            setCreateStripeLoading(false);
        } catch (error) {
            toast("An error occurred while creating the Stripe account");
        }
    };

    useEffect(() => {
        if (queryParams.get("err_msg") === "session_expired") {
            toast.error(
                `Session Expired for the strip account ${queryParams.get(
                    "account_id"
                )}`
            );
            logEvent({
                eventName: "stripe_session_expired",
                eventProperties: {
                    account_id: queryParams.get("account_id"),
                },
            });
        }
    }, []);

    useEffect(() => {
        if (externalLoginResponse?.data?.url) {
            if (externalLoginResponse?.data?.type === "account_onboarding") {
                window.location.href = externalLoginResponse?.data?.url;
                logEvent({
                    eventName: "stripe_onboarding_clicked",
                    eventProperties: {
                        account_id: accountId,
                    },
                });
            } else {
                window.open(externalLoginResponse?.data?.url, "_blank");
                logEvent({
                    eventName: "stripe_dashboard_clicked",
                    eventProperties: {
                        account_id: accountId,
                    },
                });
            }
        }
    }, [externalLoginResponse]);

    if (isLoadingConnectedAccount || isLoadingExternalLogin) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <Typography
                        className="ps-md-4 ps-0 text-md-start text-center mb-md-0"
                        variant="h1"
                    >
                        Payments
                    </Typography>
                </div>
                <div className="m-4 mb-2 divider-line"></div>
            </div>

            <div className="mx-3 row d-flex flex-wrap">
                <div className="col-12 col-md-12">
                    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                        <Typography
                            className="ps-0 text-md-start text-center mb-md-0"
                            variant="h3"
                        >
                            Stripe Profiles
                        </Typography>

                        <div className="me-0 mb-2 mb-md-0 p-md-0 p-3 pt-3">
                            <Button
                                type="button"
                                variant="primary"
                                fullWidth={false}
                                onClick={handleCreateStripe}
                            >
                                {isCreateStripeLoading ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Loading...</span>
                                    </div>
                                ) : (
                                    "Create New Profile"
                                )}
                            </Button>
                        </div>
                    </div>
                    <BasicTable
                        data={paymentData}
                        columns={[
                            {
                                header: (
                                    <div className="text-center">
                                        Profile Id
                                    </div>
                                ),
                                accessor: "account_id",
                                Cell: ({ value }) => (
                                    <div className="text-center">{value}</div>
                                ),
                            },
                            {
                                header: (
                                    <div className="text-center">
                                        Created Date
                                    </div>
                                ),
                                accessor: "created",
                                Cell: ({ value }) => (
                                    <div className="text-center">{value}</div>
                                ),
                            },
                            {
                                header: (
                                    <div className="text-center">Status</div>
                                ),
                                accessor: "status",
                                Cell: ({ value }) => (
                                    <div
                                        className={`text-center ${
                                            value === "Active"
                                                ? "status-active"
                                                : "status-inactive"
                                        }`}
                                    >
                                        {value}
                                    </div>
                                ),
                            },
                            {
                                header: (
                                    <div className="text-center">
                                        Payments enabled
                                    </div>
                                ),
                                accessor: "hasPayments",
                                Cell: ({ value }) => (
                                    <div className="text-center">
                                        {value === "Yes" ? (
                                            <i className="bi bi-check-circle-fill text-success"></i>
                                        ) : (
                                            <i className="bi bi-x-circle-fill text-danger"></i>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                header: (
                                    <div className="text-center">
                                        Charges enabled
                                    </div>
                                ),
                                accessor: "hasCharges",
                                Cell: ({ value }) => (
                                    <div className="text-center">
                                        {value === "Yes" ? (
                                            <i className="bi bi-check-circle-fill text-success"></i>
                                        ) : (
                                            <i className="bi bi-x-circle-fill text-danger"></i>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                header: (
                                    <div className="text-center">
                                        Details Submitted
                                    </div>
                                ),
                                accessor: "details_submitted",
                                Cell: ({ value }) => (
                                    <div className="text-center">
                                        {value === "Yes" ? (
                                            <i className="bi bi-check-circle-fill text-success"></i>
                                        ) : (
                                            <i className="bi bi-x-circle-fill text-danger"></i>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                header: <div className="text-center"></div>,
                                accessor: "action",
                                Cell: ({ row }) => (
                                    <div className="d-flex flex-row justify-content-end">
                                        {row.original.status === "Inactive" ? (
                                            <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => {
                                                    handleUpdateProfileButton(
                                                        row.original.account_id
                                                    );
                                                }}
                                            >
                                                <i className="bi bi-box-arrow-up-right mx-2"></i>
                                                Complete onboarding
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outline-primary"
                                                className="ms-1"
                                                size="sm"
                                                onClick={() => {
                                                    handleUpdateProfileButton(
                                                        row.original.account_id
                                                    );
                                                }}
                                            >
                                                <i className="bi bi-eye-fill mx-2"></i>
                                                View stripe dashboard
                                            </Button>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={paymentData?.length > 5}
                        pageSize={5}
                        currentPage={0}
                        showCheckbox={false}
                    />
                </div>
            </div>
        </div>
    );
}
