export const ProjectTemplates = {
    "SUPPLY_CHAIN": "SUPPLY_CHAIN",
    "TOKENIZATION": "BAO"
}

export function getProjectTemplateFromAPI(apiTemaplte) {
    switch (apiTemaplte) {
        case "SUPPLY_CHAIN":
            return ProjectTemplates.SUPPLY_CHAIN;

        case "BAO":
            return ProjectTemplates.TOKENIZATION;

        default:
            return null;
    }
}

export function getProjectTemplateName(template) {
    switch (template) {
        case "SUPPLY_CHAIN":
            return "Supply chain traceability";

        case "BAO":
            return "Tokenization";

        default:
            return "Other";
    }
}

export function string_to_snake_case(str) {
    // Check if str is falsy or not a string
    if (!str || typeof str !== 'string') {
        return ''; // Return empty string if str is falsy or not a string
    }

    // Regular expression to split camelCase and words with numbers
    const regex = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

    // Convert matched parts to lowercase and join with underscore
    return str.trim() // Trim leading and trailing whitespace
        .match(regex)
        ?.map(s => s.toLowerCase()) // Use optional chaining to avoid null
        ?.join('_') || ''; // Use default empty string if null/undefined
}
