import React, { useState } from "react";

export default function SearchableInput(props) {
    const { name, label, options, ...rest } = props;
    const [inputValue, setInputValue] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setFilteredOptions(
            options.filter((option) =>
                option.label
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            )
        );
    };

    return (
        <>
            <div className="search-input">
                <input
                    className="form-control"
                    name={name}
                    id={name}
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="Search by name"
                    {...rest}
                />
                <i className="bi bi-search search-icon"></i>
            </div>
            {inputValue && (
                <ul className="list-group">
                    {filteredOptions.map((option, index) => (
                        <li key={index} className="list-group-item">
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}