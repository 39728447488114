import * as amplitude from '@amplitude/analytics-browser';
const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

export const initAmplitude = () => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
};

export const setUserProperties = (userProperties) => {
    if (NODE_ENV === 'production' && AMPLITUDE_API_KEY) {
        amplitude.setUserId(userProperties);
    } else {
        console.log(`setting user "${userProperties}".`, userProperties);
    }
}

export const logEvent = ({ eventName, eventProperties }) => {
    const userId = amplitude.getUserId();
    console.log(userId, 'userId');
    if (NODE_ENV === 'production' && AMPLITUDE_API_KEY != null) {
        amplitude.setUserId(userId);
        amplitude.track(eventName, eventProperties);
    } else {
        amplitude.setUserId(userId);
        console.log(`Event "${eventName}" for "${userId}" occurred.`, eventProperties);
    }
}

export const updateAmplitudeUserId = (userId = null) => {
    amplitude.setUserId(userId);
}
