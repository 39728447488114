import React from "react";
import { useField } from "formik";
import "./form.scss";
// TODO: show selected file name and other UI feedback

/**
 * Native HTML `file` input with Formik integration.
 * @param {{
 * name: string
 * label: string
 * disabled ?: boolean
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function FileField(props) {
    const { name, label, required = false, onChange, ...rest } = props;
    const [, meta, helpers] = useField(props);
    return (
        <>
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}{" "}
                    {required && <span className="required-field">*</span>}
                </label>
            )}
            <input
                {...rest}
                type="file"
                onChange={(e) => {
                    helpers.setValue(e.currentTarget.files[0]);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                className="form-control"
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {meta.error}
                </div>
            ) : null}
        </>
    );
}
