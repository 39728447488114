import React from "react";
import "./card.scss";
import { Icon } from "../../Icon";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../../utils/amplitudeUtlis";

export default function ProjectCard({
    title,
    subtitle,
    image,
    linkText,
    height,
    width,
    link,
    time,
    onClick,
}) {
    const navigate = useNavigate();

    const handleOnProjectLinkClick = () => {
        logEvent({
            eventName: "project_card_clicked",
            eventProperties: {
                project_name: title,
                project_type: subtitle,
            },
        });
        navigate(link);
    };

    return (
        <div className="card project-card w-100" onClick={onClick}>
            <div className="card-body">
                <div className="circular-border">
                    <img
                        className="card-img-top"
                        src={image}
                        alt="card-icon"
                        height={height}
                        width={width}
                    />
                </div>
                <h5 className="card-title mt-2 mb-0">{title}</h5>
                <p className="card-text mt-0 mb-3">{time}</p>
                <h6 className="card-subtitle mt-0">
                    <span>{subtitle}</span>
                </h6>

                <div className="mt-4">
                    <div
                        className="card-link"
                        onClick={handleOnProjectLinkClick}
                    >
                        {linkText}
                        <Icon className="ms-1" src="/icons/arrow-right.svg" />
                    </div>
                </div>
            </div>
        </div>
    );
}
