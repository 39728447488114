import React from "react";
import "./card.scss";

export default function ProjectDashboardCard({ name, value, style, variant }) {
    const cardStyle =
        variant === "main" ? "transparent-card" : "dashboard-card ";

    return (
        <div className={`card w-100 ${cardStyle}`} style={style}>
            <div className="card-body">
                <div className="card-title">
                    <div>
                        <h6 className="m-3 card-subtitle name-text mb-2">
                            {name}
                        </h6>
                        <span>
                            <h2>
                                <span className="p-3 number-text">{value}</span>
                            </h2>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
