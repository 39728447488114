import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useCreateProjectMutation } from "../../services/projectService";
import { Form } from "../../components/forms";
import Button from "../../components/Button/Button";
import TextField from "../../components/forms/TextField";
import { logEvent } from "../../utils/amplitudeUtlis";

export default function CreateTaaSProject() {
    const [createProject] = useCreateProjectMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage] = useState(null);
    const navigate = useNavigate();

    const handleAddProjectSubmit = async (values) => {
        setIsLoading(true);
        const projectName = values.projectTitle;
        try {
            const addProjectRes = await createProject({
                name: projectName,
                template: "BAO",
            });

            if (addProjectRes.data) {
                setIsLoading(true);
                toast("Project created");
                navigate(`/overview`);
                logEvent({
                    eventName: "project_creation_completed",
                    eventProperties: {
                        project_name: projectName,
                        project_id: addProjectRes?.data?.data?.id,
                        template: "BAO",
                    },
                });
            } else {
                if (addProjectRes?.error?.data?.errors[0]?.template) {
                    toast(addProjectRes?.error?.data?.errors[0].template[0]);
                    logEvent({
                        eventName: "project_creation_failed",
                        eventProperties: {
                            errorMessage:
                                addProjectRes?.error?.data?.errors[0]
                                    .template[0],
                        },
                    });
                } else {
                    toast(
                        addProjectRes?.error?.data?.errors[0] ||
                            addProjectRes?.error?.data?.errors[0]?.name[0]
                    );
                    logEvent({
                        eventName: "project_creation_failed",
                        eventProperties: {
                            errorMessage:
                                addProjectRes.error.data.errors[0] ||
                                addProjectRes.error.data?.errors[0].name,
                        },
                    });
                }
            }
            setIsLoading(false);
        } catch (error) {}
    };

    const validationSchema = yup.object({
        projectTitle: yup
            .string()
            .required("Project title is required")
            .min(2, "Project title should be of minimum 2 characters length")
            .max(50, "Project title should be of maximum 50 characters length")
            .trim("Project title must not be empty or just spaces")
            .required("Project title must not be empty or just spaces"),
    });

    return (
        <div>
            <Form
                initialValues={{
                    projectTitle: "",
                    // noOfToken: "",
                    // totalFundingAmount: "",
                }}
                onSubmit={handleAddProjectSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                <div className="w-100 mt-3">
                    <TextField
                        name="projectTitle"
                        label="Project title"
                        style={{ width: "100%" }}
                    />
                </div>

                <div className="mt-5">
                    <Button type="submit" variant="primary" fullWidth={false}>
                        {isLoading ? "Loading..." : "Continue"}
                    </Button>
                </div>
                {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                )}
            </Form>
        </div>
    );
}
