import {
    EmbeddedCheckout,
    EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useAddress, useWallet } from "@thirdweb-dev/react";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCreateClientSecretMutation } from "../../../../services/taasService";
import "./customer.scss";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

export function CustomerTokenCheckout({ projectId }) {
    const walletAddress = useAddress();
    const wallet = useWallet("embeddedWallet");
    const [email, setEmail] = useState(null);

    const [createClientSecret] = useCreateClientSecretMutation();

    const fetchClientSecret = useCallback(async () => {
        try {
            const clientSecretRes = await createClientSecret({
                projectId: projectId,
                is_ui_embedded: true,
            });

            if (clientSecretRes?.data?.data) {
                return clientSecretRes?.data?.data?.client_secret;
            } else {
                console.error(
                    "Failed to get client secret: clientSecretRes.data.data is null"
                );
            }
        } catch (error) {
            console.error("Failed to create client secret:", error);
        }
    }, [projectId, createClientSecret]);

    const options = { fetchClientSecret };

    useEffect(() => {
        async function getEmail() {
            const _email = await wallet?.getEmail();
            setEmail(_email);
        }
        getEmail();
    }, [wallet]);

    if (!walletAddress || !email) {
        return "";
    }

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    );
}

export default function CustomerTokenPurchase({
    name,
    short_description,
    description,
    price,
    image,
    isDemo = true,
}) {
    const { projectId } = useParams();

    return (
        <div className="w-100">
            <div className="row d-flex flex-row justify-content-md-center">
                <div className="m-0">
                    <CustomerTokenCheckout projectId={projectId} />
                </div>
            </div>
        </div>
    );
}
