import React from "react";
import { useParams } from "react-router-dom";
import CustomerTokenPurchase from "./CustomerTokenPurchase";
import {
    useGetPublicAssetQuery,
    useGetPublicTokenizationQuery,
} from "../../../../services/taasService";
import Loading from "../../../../components/LoadingSkeleton/Loading";

const demoData = {
    name: "Awesome Project Token",
    price: "200",
    shortDescription:
        "This token is attached to the Awesome project by Hyperglade",
    description:
        "Awesome project aims to plant 10,000 trees in Colombo thereby reducing the carbon emissions by 0.5%, with an offset of 12t carbon.",
};

export default function TokenPurchasePage() {
    const { projectId } = useParams();

    const { data: tokenizationResponse, isLoading: isLoadingProject } =
        useGetPublicTokenizationQuery({ projectId });

    const { data: assetResponse, isLoading: isLoadingAsset } =
        useGetPublicAssetQuery(
            { projectId, assetId: tokenizationResponse?.data.root_asset },
            {
                skip: !tokenizationResponse,
            }
        );

    const tokenData = assetResponse?.data ?? demoData;

    if (isLoadingProject || isLoadingAsset) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="container-fluid dashboard-container w-100 h-100 min-vh-100">
            <div className="col-12 col-md-8 offset-md-2 mt-5">
                <CustomerTokenPurchase {...tokenData} isDemo={false} />
            </div>
        </div>
    );
}
