import { useEffect, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import {
    useGetTenantQuery,
    useUpdateTenantOrganizationMutation,
} from "../../../services/tenantService";
import { Form, TextField } from "../../../components/forms";
import * as Yup from "yup";

export default function OrganizationPage() {
    const [formData, setFormData] = useState({ organizationName: "" });
    const [updateOrganization] = useUpdateTenantOrganizationMutation();
    const [isLoading, setIsLoading] = useState(false);
    const { data: tenantData, refetch } = useGetTenantQuery();
 

    const handleUpdateDemoData = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    const handleSaveChangesClick = async (values) => {
        console.log(values, "formvalue");
        setIsLoading(true);
        try {
            const response = await updateOrganization({
                tenantId: tenantData?.data?.id,
                name: values.name,
            });
            if (response.error) {
                throw "Error in updating organization";
            }
            toast.success("Organization updated successfully");
            refetch(); // Refetch the tenant data after successful update
        } catch (error) {
            toast.error("Error in updating organization");
        } finally {
            setIsLoading(false);
        }
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Organization Name is required")
            .trim("Organization Name cannot start or end with whitespace"),
    });

    useEffect(() => {
        if (tenantData) {
            setFormData({ ...tenantData.data });
        }
    }, [tenantData]);

    console.log(formData, "formData");

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 pb-2 mb-3 d-md-flex justify-content-md-between">
                    <Typography
                        className="ps-md-4 ps-0 text-md-start text-center mb-md-0"
                        variant="h1"
                    >
                        Organization
                    </Typography>
                </div>
                <div className="m-4 mb-2 divider-line"></div>
            </div>

            <div className="mx-3 row d-flex flex-wrap">
                <div className="col-12 col-md-6 py-4 d-flex flex-column justify-content-md-start align-items-md-start text-md-start">
                    <Form
                        initialValues={formData}
                        onSubmit={handleSaveChangesClick}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                    >
                        <TextField
                            name="name"
                            label="Organization Name"
                            type="text"
                            value={formData.name}
                            onChange={(e) =>
                                handleUpdateDemoData("name", e.target.value)
                            }
                        />
                        <Button
                            className="mt-3"
                            type="submit"
                            fullWidth={false}
                        >
                            {isLoading ? (
                                <div>
                                    <span
                                        className="spinner-border spinner-border-sm mx-2"
                                        aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                </div>
                            ) : (
                                "Save Changes"
                            )}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}
