import React, { useState } from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export function AssetTrace({ assetTrace }) {
    const [selectedAsset, setSelectedAsset] = useState(null);

    return (
        <div className="row w-100">
            <div className="col-6 asset-timeline">
                <VerticalTimeline layout="1-column-left">
                    {Object.keys(assetTrace).map((k) => {
                        const assets = assetTrace[k];

                        const assetCards = assets.map((asset) => (
                            <div
                                className="w-25 asset-trace-card"
                                onClick={() => setSelectedAsset(asset)}
                            >
                                <h5>{asset.asset_id}</h5>
                                <p>{asset.node.name}</p>
                            </div>
                        ));

                        return (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{
                                    borderTop: "2px solid  #1c8ad1",
                                }}
                                contentArrowStyle={{
                                    borderRight: "7px solid  #1c8ad1",
                                }}
                                // date="2011 - present"
                                iconStyle={{ background: "#1c8ad1" }}
                                // icon={<WorkIcon />}
                            >
                                <div className="d-flex flex-row">
                                    {assetCards}
                                </div>
                            </VerticalTimelineElement>
                        );
                    })}
                </VerticalTimeline>
            </div>
            <div className="col-6">
                <div className="asset-trace-details">
                    {!selectedAsset && (
                        <h4>Select an Asset to view details.</h4>
                    )}

                    {selectedAsset && (
                        <div>
                            <h2>{selectedAsset.node.name}</h2>
                            <img
                                src={selectedAsset.node.promo_image}
                                width="100%"
                            />
                            <p className="mt-2">
                                {selectedAsset.node.description}
                            </p>
                            {selectedAsset.form_data !== null && (
                                <table className="table table-outlined">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedAsset.form_data?.map(
                                            (field) => (
                                                <tr>
                                                    <td>{field.label}</td>
                                                    <td>{field.value}</td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}

                            {selectedAsset.transaction_hash && (
                                <a
                                    href={`https://mumbai.polygonscan.com/tx/${selectedAsset.transaction_hash}`}
                                    className="btn btn-primary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Verify
                                </a>
                            )}
                            {selectedAsset.token_id && (
                                <a
                                    href={`https://mumbai.polygonscan.com/token/${selectedAsset.contract_address}?a=${selectedAsset.token_id}`}
                                    className="btn btn-primary ms-2"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Token
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
