import { useWallet } from "@thirdweb-dev/react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useRouting } from "../../../../hooks/routesHooks";
import "./navbar.scss";
import Button from "../../../Button/Button";

export default function Navbar({ user }) {
    const { getRouteLink } = useRouting();
    const { projectId } = useParams();
    let wallet = useWallet("embeddedWallet");
    const [email, setEmail] = useState(null);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [isEmailFetched, setIsEmailFetched] = useState(false);
    const navigate = useNavigate();
    const isLoggedIn = useMemo(
        () => user && user?.reg_status === "COMPLETED",
        [user]
    );

    const logoUrl = getComputedStyle(document.documentElement)
        .getPropertyValue("--customer-portal-logo")
        .trim();

    // console.log("logoUrl", logoUrl);

    // Remove url("...") wrapper
    let logoPath = logoUrl.slice(4, -1);
    // Check if logo is undefined
    const isLogoLoading = logoUrl === 'url("")';

    // console.log("logoPath", logoPath);
    // If no logo, set to placeholder
    // if (!logoPath) {
    //     logoPath = "/images/hyperglade-logo-black.svg";
    // }
    const fetchEmail = async () => {
        setIsEmailLoading(true);
        const _email = await wallet?.getEmail();
        setEmail(_email);
        setIsEmailLoading(false);
        setIsEmailFetched(true);
    };

    const handleBuyToken = () => {
        navigate(`/token-app/fund/${projectId}/`);
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchEmail();
        }
    }, [isLoggedIn, wallet]);

    return (
        <nav className="navbar navbar-expand-lg navbar-customer fixed-top">
            <div className="container-fluid">
                {isLoggedIn && (
                    <a
                        className="navbar-brand"
                        href={`/token-app/${projectId}`}
                    >
                        {isLogoLoading ? (
                            <div>
                                <span
                                    className="spinner-border spinner-border-sm mx-2"
                                    aria-hidden="true"
                                ></span>
                                <span role="status">Loading...</span>
                            </div>
                        ) : (
                            <img
                                src={logoPath}
                                className="ps-3 img-fluid logo"
                                alt="logo"
                            />
                        )}
                    </a>
                )}

                <button
                    className="navbar-toggler py-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-collapse collapse" id="navbarCollapse">
                    <ul className="nav navbar-nav ms-auto d-flex align-items-lg-center">
                        {isLoggedIn && (
                            <>
                                <li className="nav-item py-lg-0 py-2 px-2 px-lg-0">
                                    <NavLink
                                        className="nav-link"
                                        // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                                        //     tabName: "company",
                                        // })}
                                    >
                                        Explore
                                    </NavLink>
                                </li>

                                <li className="nav-item py-lg-0 py-2 px-2 px-lg-0">
                                    <NavLink
                                        className="nav-link"
                                        // to={getRouteLink(ROUTES.SETTINGS_PAGE, {
                                        //     tabName: "company",
                                        // })}
                                    >
                                        About
                                    </NavLink>
                                </li>

                                <li className="nav-item px-2 py-lg-0 py-2">
                                    <Button
                                        fullWidth={false}
                                        onClick={handleBuyToken}
                                        variant="primary"
                                        app="customer"
                                    >
                                        Buy a token
                                    </Button>
                                </li>
                                <li className="nav-item dropdown pe-3 px-2 py-2">
                                    <a
                                        className="d-flex flex-row align-items-center"
                                        href=" "
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div className="customer-initial-avatar">
                                            <span>
                                                {user.username[0].toUpperCase()}
                                            </span>
                                        </div>
                                    </a>

                                    <div className="m-2 dropdown-menu dropdown-menu-end">
                                        <NavLink
                                            className="dropdown-item"
                                            // to=""
                                        >
                                            <p
                                                className="m-1 border-2"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#navbarCollapse"
                                                aria-expanded="false"
                                                aria-controls="navbarCollapse"
                                            >
                                                Profile
                                            </p>
                                        </NavLink>
                                        <NavLink
                                            className="dropdown-item"
                                            to={`/token-app/${projectId}/logout`}
                                        >
                                            <p
                                                className="m-2"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#navbarCollapse"
                                                aria-expanded="false"
                                                aria-controls="navbarCollapse"
                                            >
                                                <Button
                                                    icon="/icons/logout.svg"
                                                    className="w-100 my-0"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navbarCollapse"
                                                    aria-expanded="false"
                                                    aria-controls="navbarCollapse"
                                                    app="customer"
                                                >
                                                    Sign out
                                                </Button>
                                            </p>
                                        </NavLink>
                                    </div>
                                </li>
                            </>
                        )}

                        {!isLoggedIn && !isEmailFetched && (
                            <li className="nav-item ">
                                <NavLink
                                    className="m-auto nav-link"
                                    to={`/token-app/${projectId}/login`}
                                >
                                    Sign in
                                </NavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}
