import React, { useState } from "react";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import "./project.scss";
import { useNavigate, useParams } from "react-router-dom";
import CreateSupplyChainTraceabilityProject from "../sct/CreateSupplyChainTraceabilityProject";
import CreateTaaSProject from "../taas/CreateTaaSProject";
import { logEvent } from "../../utils/amplitudeUtlis";

const headingMap = {
    "token-service": "tokenization",
    "supply-chain": "supply chain",
};

export default function CreateProject() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage] = useState(null);
    const navigate = useNavigate();

    const { projectType } = useParams();

    const title = headingMap[projectType] ? headingMap[projectType] : "";

    const handleCloseClick = () => {
        logEvent({
            eventName: "project_creation_cancelled",
            eventProperties: {
                page_name: "Create Project Page",
            },
        });
        navigate("/overview");
    };

    return (
        <div className="project-container h-100 min-vh-100 w-100 px-4">
            <div className="pt-3">
                <Typography variant="body1">
                    Overview / Create new project
                </Typography>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <Typography variant="h1">
                        Create new {title} project
                    </Typography>

                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="divider-line mt-4"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-5">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 col-md-12 mx-auto justify-content-center">
                        <Typography variant="h2">Project details</Typography>
                        {projectType === "supply-chain" && (
                            <CreateSupplyChainTraceabilityProject />
                        )}
                        {projectType === "token-service" && (
                            <CreateTaaSProject />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
