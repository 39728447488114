import React from "react";
import { BasicTable } from "../../../../components/tables";
import { Icon } from "../../../../components/Icon";

export default function NodesTab({ nodeData, editOnclick }) {
    return (
        <div className="mx-2">
            <BasicTable
                data={nodeData}
                columns={[
                    {
                        header: "Node Title",
                        accessor: "nodeTitle",
                    },
                    {
                        header: "Location",
                        accessor: "location",
                    },
                    // {
                    //     header: "Node Users",
                    //     accessor: "nodeUsers",
                    //     Cell: ({ row }) => (
                    //         <div className="d-flex align-items-center">
                    //             <div className="m-1 avatar-group">
                    //                 {Array.isArray(
                    //                     row.original.nodeUsersAvatar
                    //                 ) &&
                    //                     row.original.nodeUsersAvatar.map(
                    //                         (avatar, index) => (
                    //                             <img
                    //                                 key={index}
                    //                                 src={avatar}
                    //                                 alt={`Avatar ${index + 1}`}
                    //                                 className="rounded-circle"
                    //                                 style={{
                    //                                     border: "1px solid #fff",
                    //                                     zIndex: 3 - index,
                    //                                     position: "relative",
                    //                                     marginLeft:
                    //                                         index > 0
                    //                                             ? "-10px"
                    //                                             : "0px",
                    //                                     width: "35px",
                    //                                     height: "35px",
                    //                                 }}
                    //                             />
                    //                         )
                    //                     )}
                    //             </div>
                    //             <div className="ms-3 node-users">
                    //                 {row.original.nodeUsers}
                    //             </div>
                    //         </div>
                    //     ),
                    // },
                    // {
                    //     header: "Created by",
                    //     accessor: "createdBy",
                    //     Cell: ({ row }) => (
                    //         <div className="d-flex align-items-center">
                    //             <img
                    //                 src={row.original.createdByAvatar}
                    //                 alt={row.original.createdBy}
                    //                 className="m-2 rounded-circle me-2"
                    //                 style={{
                    //                     width: "35px",
                    //                     height: "35px",
                    //                 }}
                    //             />
                    //             {row.original.createdBy}
                    //         </div>
                    //     ),
                    // },
                    {
                        header: "Created date",
                        accessor: "createdDate",
                        Cell: ({ cell: { value } }) => {
                            const dateObject = new Date(value);
                            const formattedDate = dateObject
                                .toLocaleDateString("en-US")
                                .split("/")
                                .join("-");
                            return <div>{formattedDate}</div>;
                        },
                    },
                    // {
                    //     header: "",
                    //     accessor: "action",
                    //     Cell: () => (
                    //         <div className="d-flex flex-row justify-content-end">
                    //             <Icon
                    //                 src="/icons/edit.svg"
                    //                 className="mx-2"
                    //                 onClick={editOnclick}
                    //             />
                    //         </div>
                    //     ),
                    // },
                ]}
                isSortable={false}
                isPaginated={nodeData?.length > 8}
                pageSize={8}
                currentPage={0}
                showCheckbox={false}
            />
        </div>
    );
}
