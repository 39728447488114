import { useRouting } from "../../hooks/routesHooks";
import { useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { logEvent } from "../../utils/amplitudeUtlis";
import { useEffect, useState } from "react";
import Button from "../Button/Button";

export default function Sidebar({
    sidebarItems,
    onChangeItem,
    projectData,
    assetResponse,
    fundRaise,
    fundingLoading,
    brandLogo,
}) {
    const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
    const navigate = useNavigate();
    const template = projectData?.data?.template;
    const projectTitle = projectData?.data?.name;
    // const projectLogo = assetResponse?.data?.image?.file || "";
    const projectLogo = "";
    const isLive = assetResponse?.data?.is_live || false;
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = (path) => {
        navigate(path);
        onChangeItem(path);
        logEvent({
            eventName: "sidebar_item_clicked",
            eventProperties: {
                item: path,
            },
        });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsOpen(window.innerWidth > 768);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <nav
                id="sidebarMenu"
                className={`d-block sidebar ${
                    isOpen ? "open" : ""
                } d-flex flex-column`} // Add flexbox classes here
            >
                <div>
                    <div className="logo_details">
                        {projectTitle && (
                            <li className="project-title d-flex align-items-center">
                                <div className="project-logo">
                                    {brandLogo ? (
                                        <img
                                            src={brandLogo}
                                            alt="Project Logo"
                                        />
                                    ) : (
                                        `${projectTitle[0].toUpperCase()}`
                                    )}
                                </div>
                                <span className="sidebar-text ms-2">
                                    {projectTitle.charAt(0).toUpperCase() +
                                        projectTitle.slice(1)}
                                </span>
                            </li>
                        )}
                        <i
                            className="bi bi-list"
                            id="btn"
                            onClick={toggleSidebar}
                        ></i>
                    </div>
                    <ul className="nav-list p-0">
                        {sidebarItems?.map((item) => (
                            <li key={item.path}>
                                <a
                                    className={`nav-link ${
                                        item.active ? "active" : ""
                                    }`}
                                    aria-current="page"
                                    onClick={() => handleLinkClick(item.path)}
                                >
                                    <i
                                        className={`${
                                            item.icon ||
                                            "bi bi-exclamation-square"
                                        }`}
                                    ></i>
                                    <span className="link_name">
                                        {item.name}
                                    </span>
                                </a>
                                <span className="tooltip">{item.name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="mt-auto mb-5 pb-4">
                    {template === "BAO" && (
                        <Button
                            variant="primary"
                            type="button"
                            className={`funding-button border-0 ${
                                isLive ? "stop" : "start"
                            } ${isLive ? "bg-danger" : ""}`} // Add conditional class here
                            // disabled={!fundingStarted}
                            onClick={fundRaise}
                        >
                            {fundingLoading ? (
                                <div>
                                    <span
                                        className="spinner-border spinner-border-sm mx-2"
                                        aria-hidden="true"
                                    ></span>
                                    <span role="status">Loading...</span>
                                </div>
                            ) : isOpen ? (
                                isLive ? (
                                    "Stop Funding"
                                ) : (
                                    "Start Funding"
                                )
                            ) : (
                                <i className="bi bi-cash"></i>
                            )}
                        </Button>
                    )}
                </div>
            </nav>
        </>
    );
}
