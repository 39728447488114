import React, { useEffect, useRef } from "react";
import { Toast } from "bootstrap";
import "./toast.scss";

export default function ToastComponent({ message, type }) {
    const toastRef = useRef();

    useEffect(() => {
        var myToast = toastRef.current;
        var bsToast = Toast.getInstance(myToast);

        if (!bsToast) {
            // initialize Toast
            bsToast = new Toast(myToast);
        }

        if (message) {
            bsToast.show();

            // Hide the toast after 6 seconds
            const timeoutId = setTimeout(() => {
                bsToast.hide();
            }, 6000);

            // Clear the timeout when the component is unmounted or when the message changes
            return () => clearTimeout(timeoutId);
        } else {
            bsToast.hide();
        }
    }, [message]); // re-run the effect when `message` changes

    const toastClass = type === "success" ? "toast-success" : "toast-error";

    return (
        <div className="py-2">
            <div
                className={`toast position-absolute bottom-0 end-0 m-4 ${toastClass}`}
                role="alert"
                ref={toastRef}
            >
                <div className="d-flex">
                    <div className="toast-body">{message}</div>
                    <button
                        type="button"
                        className="btn-close btn-close me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    ></button>
                </div>
            </div>
        </div>
    );
}
