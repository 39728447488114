import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { authAPI } from "./services/authService";
import { supplyChainAPI } from "./services/supplyChainService";
import { tenantAPI } from "./services/tenantService";
import { projectAPI } from "./services/projectService";
import projectDetailReducer from "./services/projectDetailsSlice";
import { userAPI } from "./services/userService";
import { taasAPI } from "./services/taasService";
import { authReducer } from "./services/authSlice";
import { billingAPI } from "./services/billingService";
import { fileManagementAPI } from "./services/fileManagementService";


const combinedReducer = combineReducers({
    // counter: counterReducer, //TODO: sample service. remove in actual projects
    [authAPI.reducerPath]: authAPI.reducer,
    auth: authReducer,
    [tenantAPI.reducerPath]: tenantAPI.reducer,
    [projectAPI.reducerPath]: projectAPI.reducer,
    [supplyChainAPI.reducerPath]: supplyChainAPI.reducer,
    projectDetail: projectDetailReducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [taasAPI.reducerPath]: taasAPI.reducer,
    [billingAPI.reducerPath]: billingAPI.reducer,
    [fileManagementAPI.reducerPath]: fileManagementAPI.reducer
    // [indexerAPI.reducerPath]: indexerAPI.reducer
    // ... add your reducers here
});

// we structure store like this so that we can easily reset the store on logout.
const rootReducer = (state, action) => {
    if (action.type === "auth/logout") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    //add rtkq middleware to below array
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(authAPI.middleware)
            .concat(tenantAPI.middleware)
            .concat(projectAPI.middleware)
            .concat(supplyChainAPI.middleware)
            .concat(userAPI.middleware)
            .concat(taasAPI.middleware)
            .concat(billingAPI.middleware)
            .concat(fileManagementAPI.middleware),
    devTools: true,
});
