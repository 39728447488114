import React, { useEffect, useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import {
    useGetPurchaseRecordQuery,
    useGetPaymentRecordDetailsQuery,
} from "../../../../services/taasService";
import { useParams } from "react-router-dom";

const FundingDetail = () => {
    const { projectId } = useParams();
    const { paymentId } = useParams();
    const [transaction, setTransaction] = useState(null);
    const { data: paymentDetailResponse, isLoading: isLoadingPurchaseRecord } =
        useGetPaymentRecordDetailsQuery({ projectId, paymentId });

    useEffect(() => {
        if (paymentDetailResponse) {
            setTransaction(paymentDetailResponse.data);
        }
    }, [paymentDetailResponse]);

    if (isLoadingPurchaseRecord) {
        return <div>Loading...</div>;
    }

    if (!transaction) {
        return <div>No data available</div>;
    }

    return (
        <div className="container p-5">
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        My projects / Project Details
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="h1">
                        Funding Details
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        Pledge Amount
                    </Typography>
                </div>
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body1">
                        ${transaction.payment_amount}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        Pledge Date
                    </Typography>
                </div>
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body1">
                        {new Date(transaction.payment_date).toLocaleDateString(
                            "en-US",
                            { month: "short", day: "numeric", year: "numeric" }
                        )}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        Pledge ID
                    </Typography>
                </div>
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body1">
                        {transaction.payment_id}
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="h1">
                        Payment Details
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        Payment method
                    </Typography>
                </div>
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body1">
                        Card
                    </Typography>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body2">
                        Payment Status
                    </Typography>
                </div>
                <div className="col-12 col-md-6">
                    <Typography className="pt-3" variant="body1">
                        {transaction.payment_status.charAt(0).toUpperCase() +
                            transaction.payment_status.slice(1)}{" "}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default FundingDetail;
