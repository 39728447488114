import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useProfileQuery } from "../../../services/userService";
import { useGetCustomerPortalThemeSettingQuery } from "../../../services/taasService";
import Loading from "../../../components/LoadingSkeleton/Loading";
import Navbar from "../../../components/taas/customer/Navbar/Navbar";
export default function TaaSCustomerAppLayout() {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const { projectId } = useParams();

    const primaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--customer-portal-primary-color")
        .trim();
    const secondaryColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--customer-portal-secondary-color")
        .trim();
    const navBarColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--customer-portal-nav-bar-color")
        .trim();
    const logoUrl = getComputedStyle(document.documentElement)
        .getPropertyValue("--customer-portal-logo")
        .trim();

    const [theme, setTheme] = useState({
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        nav_bar_color: navBarColor,
        logo: logoUrl,
    });

    const { data: themeResponse, isLoading: isThemeLoading } =
        useGetCustomerPortalThemeSettingQuery(
            {
                projectId: projectId,
            },
            {
                skip: !isLoggedIn,
            }
        );

    const { data: profileData, refetch } = useProfileQuery(
        {},
        {
            skip: !isLoggedIn,
        }
    );

    let user = profileData?.data || null;

    useEffect(() => {
        if (isLoggedIn) {
            refetch();
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (themeResponse) {
            if (themeResponse.data && themeResponse.data.length === 0) {
                // Set default theme here
                setTheme({
                    primary_color: primaryColor,
                    secondary_color: secondaryColor,
                    nav_bar_color: navBarColor,
                    logo: logoUrl,
                });
            } else {
                setTheme({ ...themeResponse?.data[0] });
            }
        }
    }, [themeResponse]);

    useEffect(() => {
        document.documentElement.style.setProperty(
            "--customer-portal-primary-color",
            theme?.primary_color
        );
        document.documentElement.style.setProperty(
            "--customer-portal-secondary-color",
            theme?.secondary_color
        );
        document.documentElement.style.setProperty(
            "--customer-portal-nav-bar-color",
            theme?.nav_bar_color
        );
        document.documentElement.style.setProperty(
            "--customer-portal-logo",
            `url(${theme?.logo?.file ? theme?.logo?.file : theme?.logo})`
        );
    }, [theme]);

    return (
        <>
            {isThemeLoading ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                >
                    <Loading />
                </div>
            ) : (
                <>
                    {isLoggedIn && <Navbar user={user} />}
                    <div className="container-fluid">
                        <main className="p-0">
                            <Outlet />
                        </main>
                    </div>
                </>
            )}
        </>
    );
}
