import { BasicTable } from "../../../components/tables";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllBatchesQuery } from "../../../services/supplyChainService";
import { useModal } from "../../../hooks/modalsHooks";
import Loading from "../../../components/LoadingSkeleton/Loading";

export default function BatchTab({ tableData }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        data: batchesResponse,
        isLoading: isLoadingBatches,
        refetch,
    } = useGetAllBatchesQuery(id);

    const batchesList = batchesResponse?.data;
    const batchData =
        batchesList?.map((batch) => {
            return {
                batchId: batch.id,
                batchName: batch.name,
            };
        }) || [];

    const { showModal } = useModal();

    const handleAddBatches = () => {
        showModal({
            modalType: "CreateBatchModal",
            modalProps: {
                projectId: id,
                refetch, // Pass refetch to modal
            },
        });
    };

    const handleViewClick = (batchId) => {
        navigate(`/overview/${id}/batches/${batchId}`);
    };

    if (isLoadingBatches) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 col-md-12">
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h3">Batches</Typography>
                        <div className="ps-md-3 ps-0 pb-1 subtitle">
                            {batchData.length} total batches
                        </div>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            className="text-truncate"
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            onClick={handleAddBatches}
                        >
                            Add Batch
                        </Button>
                    </div>
                </div>

                <BasicTable
                    data={batchData}
                    columns={[
                        {
                            header: "Batch Name",
                            accessor: "batchName",
                        },
                        {
                            header: "",
                            accessor: "action",
                            Cell: ({ row }) => (
                                <div className="d-flex flex-row justify-content-end">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        fullWidth={false}
                                        onClick={() => {
                                            handleViewClick(
                                                row.original.batchId
                                            );
                                        }}
                                    >
                                        View
                                    </Button>
                                </div>
                            ),
                        },
                    ]}
                    isSortable={false}
                    isPaginated={batchData?.length > 5}
                    pageSize={5}
                    currentPage={0}
                    showCheckBox={false}
                />
            </div>
        </div>
    );
}
