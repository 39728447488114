// Desc: Backers Tab for the taas project page

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../../components/Typography/Typography";
import "./backersTab.scss";
import { BasicTable } from "../../../../components/tables";
import SearchableInput from "../../../../components/forms/Search";
import { useGetProjectBackersQuery } from "../../../../services/taasService";
import { timeAgo } from "../../../../components/TimeAgo/timeAgo";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import { useModal } from "../../../../hooks/modalsHooks";

function BackersTab() {
    const { id } = useParams();
    const options = [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
        // add more options here
    ];
    const { showModal } = useModal();
    const { hideModal } = useModal();
    const navigate = useNavigate();
    const {
        data: backersResponse,
        isLoading,
        refetch,
        error,
    } = useGetProjectBackersQuery({ projectId: id });

    // Transform the backers data into an array of objects
    const backers = Object.entries(backersResponse?.data || {}).map(
        ([email, details]) => ({
            // name: email,
            email: email,
            totalFunding: `$ ${details.total_investment}`,
            lastFundingDate: timeAgo(details.updated_at),
            backing: details.num_of_backings,
        })
    );

    const handleContinue = async () => {
        hideModal();
        navigate(`/overview/${id}/configuration`);
        window.location.reload();
    };

    const handleModalClose = () => {
        hideModal();
    };

    useEffect(() => {
        if (
            error &&
            error?.data?.errors[0] === "Project must have a root asset"
        ) {
            showModal({
                modalType: "PopUpModal",
                modalProps: {
                    isOpen: true,
                    projectId: id,
                    type: "settings",
                    title: "Set up the project to view backers",
                    message:
                        "To access the backers, you need to set up your project. Click 'Continue' to start setting up your project and get access to your backers.",
                    handleContinue: handleContinue,
                    handleClose: handleModalClose,
                },
            });
        }
    }, [error]);

    if (isLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="row d-flex flex-wrap">
            <div className="col-12 col-md-12">
                <div className="d-flex flex-column flex-md-column my-2">
                    <Typography variant="h1" className="p-0">
                        Backers
                    </Typography>
                    <Typography variant="body2" className="p-0 m-0">
                        Manage and communicate with your backers
                    </Typography>
                </div>
            </div>
            <div className="col-12 col-md-12 my-3">
                <SearchableInput name="mySearchableSelect" options={options} />{" "}
            </div>
            <div className="col-12 col-md-12 my-2">
                <BasicTable
                    data={backers}
                    columns={[
                        // {
                        //     header: "Name",
                        //     accessor: "name",
                        // },
                        {
                            header: "Email",
                            accessor: "email",
                        },
                        {
                            header: "Total Funding",
                            accessor: "totalFunding",
                        },
                        {
                            header: "Last Funding Date",
                            accessor: "lastFundingDate",
                        },
                        {
                            header: "Number of Backings",
                            accessor: "backing",
                        },
                    ]}
                    isSortable={false}
                    isPaginated={backers?.length > 5}
                    pageSize={5}
                    currentPage={0}
                    showCheckbox={false}
                />
            </div>
        </div>
    );
}

export default BackersTab;
