import { useParams } from "react-router-dom";
import Typography from "../../../../components/Typography/Typography";
import IntegrationItem from "../../../../components/IntegrationItem/IntegrationItem";
import "./settingsTab.scss";
import PaymentSetting from "./PaymentSetting";
import ThemeSetting from "./ThemeSetting";

export default function Settings() {
    // Hooks
    const { id } = useParams();

    return (
        <div className="row d-flex flex-wrap">
            <div className="col-12 col-md-12">
                <div className="d-flex flex-column flex-md-column my-2">
                    <Typography variant="h1" className="p-0">
                        Settings
                    </Typography>
                </div>
            </div>
            <div className="col-12 col-md-12">
                <PaymentSetting id={id} />
            </div>
            <div className="col-12 col-md-12">
                <ThemeSetting id={id} />
            </div>

            <div className="col-12 col-md-6 mb-3">
                <Typography variant="h3" className="py-3">
                    Integration Details
                </Typography>

                <IntegrationItem
                    imageUrl="/images/embedded-button.svg"
                    title="Embedded Button"
                    subtitle="Click to copy the  embed code"
                    // dateAdded="10 days ago"
                    //   onDelete={handleDelete}
                />
            </div>
        </div>
    );
}
