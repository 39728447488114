import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
    ConnectEmbed,
    lightTheme,
    useAddress,
    useAuth,
    useWallet,
} from "@thirdweb-dev/react";
import { useDispatch } from "react-redux";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import Typography from "../../../../components/Typography/Typography";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "../../../../constants";
import { useLoginMutation } from "../../../../services/authService";
import { authActions } from "../../../../services/authSlice";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import "./auth.scss";

export default function CustomerAuthPage() {
    const thirdwebAuth = useAuth();
    const navigate = useNavigate();
    const address = useAddress();
    const wallet = useWallet("embeddedWallet");
    const [signIn] = useLoginMutation();
    const location = useLocation();
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const invitation = queryParams.get("invitation");

    useEffect(() => {
        if (address) {
            login();
        }
    }, [address]);

    const login = async () => {
        try {
            logEvent({
                eventName: "taas_user_login_initiated",
                eventProperties: {
                    project_id: projectId,
                },
            });
            setIsLoading(true);
            const payload = await thirdwebAuth.login();
            const email = await wallet.getEmail();
            const metaData = wallet.getMeta();

            const signInRes = await signIn({
                payload,
                email,
                metaData,
                invitationToken: invitation || "",
                external: true,
                project_id: projectId,
            });
            if (signInRes.data) {
                logEvent({
                    eventName: "taas_user_login_completed",
                    eventProperties: {
                        project_id: projectId,
                    },
                });
                if (
                    signInRes.data.data?.tokens.access ||
                    signInRes.data.data?.tokens.refresh
                ) {
                    sessionStorage.setItem(
                        ACCESS_TOKEN_NAME,
                        signInRes.data.data.tokens.access
                    );

                    localStorage.setItem(
                        REFRESH_TOKEN_NAME,
                        signInRes.data.data.tokens.refresh
                    );

                    dispatch(authActions.login(signInRes.data.data.user));

                    navigate(
                        signInRes.data.data.user.role === "CUSTOMER"
                            ? `/token-app/${projectId}`
                            : "/overview"
                    );
                }
            }
            setIsLoading(false);
        } catch (error) {
            logEvent({
                eventName: "taas_user_login_failed",
                eventProperties: {
                    project_id: projectId,
                    error: error,
                },
            });
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
        >
            <div className="container-fluid p-4 col-12 col-md-10">
                <div className="row">
                    <div className="col-12">
                        <Typography variant="h2" className="pt-2 text-center">
                            Access your account
                        </Typography>

                        <Typography
                            variant="body1"
                            className="pt-2 text-center"
                        >
                            Please enter your credentials to access the customer
                            portal.
                        </Typography>
                    </div>
                    <div className="col-12 d-flex justify-content-center pt-2">
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <Loading />
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-center">
                                <ConnectEmbed
                                    btnTitle="Sign in"
                                    theme={lightTheme({
                                        colors: {
                                            accentText: "#1668e3",
                                            accentButtonBg: "#1668e3",
                                            modalBg: "#fcfcfc",
                                            dropdownBg: "#fcfcfc",
                                        },
                                    })}
                                    showThirdwebBranding={false}
                                    style={{
                                        minWidth: "360px",
                                        boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
