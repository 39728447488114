import React from "react";
import "./card.scss";
import { useDownloadFileQuery } from "../../../../services/fileManagementService";
import Typography from "../../../Typography/Typography";

const DashboardCard = ({ image, title, date, investment, amount, onClick }) => {
    const { data: downloadImage } = useDownloadFileQuery(
        { fileId: image },
        {
            skip: !image,
        }
    );

    return (
        <div
            className="customer-transparent-card d-flex align-items-center justify-content-between py-2 my-1 bg-transparent rounded"
            onClick={onClick}
        >
            <div className="card-icon">
                {/* <i class="bi bi-cash-stack"></i> */}
                <img
                    src={downloadImage?.data?.file}
                    alt={title}
                    className="img-fluid"
                    style={{ maxHeight: "50px" }}
                />
            </div>
            <div className="card-content d-flex flex-column ms-3">
                <Typography variant="h4" className="m-0">
                    {title}
                </Typography>
                <Typography variant="body2" className="m-0">
                    {date}
                </Typography>
                <Typography variant="body2" className="m-0">
                    Investment: {investment}
                </Typography>
            </div>
            <div className="card-amount ms-auto fw-bold">
                <Typography variant="body2" className="m-0">
                    {amount}
                </Typography>
            </div>
        </div>
    );
};

export default DashboardCard;
