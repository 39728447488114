import { ConnectWallet, useAddress, useAuth, useContract, useENS, useSDK, useUser, useWallet } from "@thirdweb-dev/react";
import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

export default function Home() {
    const { contract } = useContract("0xF8Dbbebb4617f4020F42451d5Bca6799ba123866");


    const navigate = useNavigate();

    useEffect(() => {  
        navigate("/signin")
    }, []);


    return (
        <main className="main">
            
        </main>
    );
}
