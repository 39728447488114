import { createSlice } from '@reduxjs/toolkit';

// Define initial state with loading and error handling 
const initialState = { 
    user: null, 
    isLoggedIn: false, 
};

export const authSlice = createSlice({ 
    name: 'authSlice', 
    initialState, 
    reducers: { 
        login: (state, action) => { 
            state.user = action.payload; 
            state.isLoggedIn = true; 
        }, 
        logout: (state) => {
            state.user = null;
            state.isLoggedIn = false;
        }
    }, 
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;