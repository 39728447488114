// Desc: Configuration tab for the project page

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import Button from "../../../../components/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import { Form, TextField } from "../../../../components/forms";
import TextArea from "../../../../components/forms/TextArea";
import ImageUpload from "../../../../components/forms/ImageUpload";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import { useUploadFileMutation } from "../../../../services/fileManagementService";
import {
    useCreateAssetMutation,
    useGetAssetQuery,
    useGetTokenizationQuery,
    useUpdateAssetMutation,
} from "../../../../services/taasService";
import { logEvent } from "../../../../utils/amplitudeUtlis";

export default function ConfigureTokenizationProject() {
    //Hooks and State Initialization
    const { id: projectId } = useParams();
    const navigate = useNavigate();
    const [logoImage, setLogoImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        short_description: "",
        description: "",
        supply: 0,
        price: 0,
        symbol: "",
        logo: null,
    });
    const [errorMessage] = useState(null);
    const [files, setFiles] = useState([]);

    //Queries and Mutations
    const [updateAsset] = useUpdateAssetMutation();
    const [createAsset] = useCreateAssetMutation();
    const [uploadFile] = useUploadFileMutation({
        fixedCacheKey: "shared-upload-image",
    });

    const { data: tokenizationResponse } = useGetTokenizationQuery({
        projectId,
    });
    const { data: assetResponse, isLoading: isAssetLoading } = useGetAssetQuery(
        { projectId, assetId: tokenizationResponse?.data.root_asset },
        {
            skip:
                !tokenizationResponse &&
                tokenizationResponse?.data.root_asset == null,
        }
    );

    //Derived Variables
    const isEditingLocked = tokenizationResponse?.data.root_asset !== null;
    const isLive = assetResponse?.data?.is_live;
    const downloadImage = assetResponse?.data?.image;

    //Effects
    useEffect(() => {
        if (assetResponse) {
            setFormData({ ...assetResponse.data });
        }
    }, [assetResponse]);

    useEffect(() => {
        downloadImage && setLogoImage(downloadImage);
    }, [downloadImage]);

    // Validation Schema
    const validationSchema = yup.object().shape({
        name: yup.string().required("Project name is required").trim(),
        description: yup.string().required("Description is required").trim(),
        supply: yup.number().required("Supply is required"),
        price: yup
            .number()
            .required("Price is required")
            .min(10, "Price must be more than 10")
            .max(100, "Price cannot exceed 100"),
        image: yup
            .mixed()
            .required("Logo is required")
            .test(
                "fileSize",
                "File too large",
                (value) =>
                    !value || !(value instanceof File) || value.size <= 2097152 // 2MB
            )
            .test(
                "fileFormat",
                "Unsupported Format",
                (value) =>
                    !value ||
                    !(value instanceof File) ||
                    ["image/jpg", "image/jpeg", "image/png"].includes(
                        value.type
                    )
            ),
    });

    //Handlers and Functions
    const handleConfigureProject = async (values) => {
        logEvent({
            eventName: "tass_configuration_initiated",
            eventProperties: {},
        });
        setIsLoading(true);

        // File upload
        if (files) {
            try {
                const formData = new FormData();
                formData.append("file", files);
                const response = await uploadFile({ file: formData });

                if (response.error) {
                    throw new Error("Could not upload the image");
                }
                values.image = response?.data?.data?.id;
            } catch (error) {
                console.error(error);
            }
        }

        try {
            if (isEditingLocked) {
                // This is an update
                const response = await updateAsset({
                    projectId: projectId,
                    assetId: assetResponse.data.id,
                    name: values.name,
                    symbol: "ADER",
                    short_description: "",
                    description: values.description,
                    image: values.image.id ? values.image.id : values.image,
                });

                logEvent({
                    eventName: "tass_configuration_updated",
                    eventProperties: {
                        projectId: projectId,
                        assetId: assetResponse.data.id,
                    },
                });

                if (response.error) {
                    logEvent({
                        eventName: "tass_configuration_failed",
                        eventProperties: {
                            projectId: projectId,
                            assetId: assetResponse.data.id,
                            errorMessage: response.error?.data?.errors,
                        },
                    });
                    throw "Could not update the asset record";
                }

                toast("Token details updated.");
            } else {
                // This is a new token
                const response = await createAsset({
                    projectId: projectId,
                    name: values.name,
                    symbol: "SSDD",
                    short_description: "",
                    description: values.description,
                    image: values.image,
                    price: values.price,
                    supply: values.supply,
                });

                if (response.error) {
                    logEvent({
                        eventName: "tass_configuration_failed",
                        eventProperties: {
                            projectId: projectId,
                            errorMessage: response.error?.data?.errors,
                        },
                    });
                    throw new Error("Could not create the asset record");
                }
                logEvent({
                    eventName: "tass_configuration_created",
                    eventProperties: {
                        projectId: projectId,
                    },
                });

                toast(
                    "Token details updated. You can make project live by clicking on the start fund raise"
                );
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewProject = () => {
        logEvent({
            eventName: "tass_configuration_view_project",
            eventProperties: {
                projectId: projectId,
            },
        });
        navigate(`/token-app/${projectId}`);
    };

    const handleUpdateDemoData = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    //Conditional Rendering
    if (isAssetLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 col-md-12">
                <div className="d-flex align-items-center flex-column flex-md-row my-2">
                    <Typography variant="h1">Project Details</Typography>
                </div>

                <div className="row">
                    <div className="col-12 col-md-8">
                        <Form
                            initialValues={formData}
                            onSubmit={handleConfigureProject}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                        >
                            <div className="mt-3">
                                <TextField
                                    name="name"
                                    label="Project Name"
                                    placeholder="Awesome Project Token"
                                    style={{ width: "100%" }}
                                    value={formData.name}
                                    onChange={(e) =>
                                        handleUpdateDemoData(
                                            "name",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="mt-3">
                                <TextArea
                                    name="description"
                                    label="Project Description"
                                    style={{ width: "100%" }}
                                    placeholder="Awesome project aims to plant 10,000 trees in Colombo thereby reducing the carbon emissions by 0.5%, with an offset of 12t carbon."
                                    value={formData.description}
                                    onChange={(e) =>
                                        handleUpdateDemoData(
                                            "description",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-md-6">
                                    <TextField
                                        name="price"
                                        type="number"
                                        label="Funding Amount"
                                        style={{ width: "100%" }}
                                        value={formData.price}
                                        placeholder="100"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value >= 0) {
                                                handleUpdateDemoData(
                                                    "price",
                                                    value
                                                );
                                            }
                                        }}
                                        disabled={isEditingLocked}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <TextField
                                        name="supply"
                                        type="number"
                                        label="Backers Limit"
                                        style={{ width: "100%" }}
                                        value={formData.supply}
                                        placeholder="Keep 0 for unlimited tokens"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value >= 0) {
                                                handleUpdateDemoData(
                                                    "supply",
                                                    value
                                                );
                                            }
                                        }}
                                        disabled={isEditingLocked}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="d-flex align-items-center gap-3">
                                    <ImageUpload
                                        label="Project Logo"
                                        name="image"
                                        onFileSelected={setFiles}
                                        logoImage={logoImage}
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                handleUpdateDemoData(
                                                    "image",
                                                    file
                                                );
                                            }
                                        }}
                                        width="100%"
                                    />
                                </div>
                            </div>

                            <div className="my-4 d-flex flex-wrap">
                                <div className="my-2 m-lg-2 m-0 col-12 col-lg-auto">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        fullWidth={false}
                                        isLoading={isLoading}
                                        className="w-100"
                                    >
                                        Save Details
                                    </Button>
                                </div>
                                {isLive && (
                                    <div className="my-2 m-lg-2 m-0 col-12 col-lg-auto">
                                        <Button
                                            type="button"
                                            variant="primary"
                                            fullWidth={false}
                                            onClick={handleViewProject}
                                            className="w-100"
                                        >
                                            View Project
                                        </Button>
                                    </div>
                                )}
                            </div>
                            {errorMessage && (
                                <div className="error-message">
                                    {errorMessage}
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}
