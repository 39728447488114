// Desc: Dashboard Tab for the project page

import React, { useState } from "react";
import { AssetTrace } from "./AssetTrace";
import Button from "../../../components/Button/Button";
import { useLazyGetTraceAssetQuery } from "../../../services/supplyChainService";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../components/Typography/Typography";

function TraceTab() {
    const { id: projectId } = useParams();
    const [assetId, setAssetId] = useState(null);
    const [assetTrace, setAssetTrace] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    const [getTrace, results] = useLazyGetTraceAssetQuery();

    const handleTraceAsset = async (values) => {
        setIsLoading(true);

        try{
            const trace = await getTrace({
                id: projectId,
                asset_id: assetId
            })

            if(trace.error){
                throw "Error in trace";
            }

            navigate(`/app/${projectId}/trace/${assetId}`)

            // setAssetTrace(trace.data.data)
        }catch(error){
            
        }finally{
            setIsLoading(false);
        }
        
    }

    return (
        <div className="row d-flex flex-wrap">
            <div className="col-12 col-md-12">
                <div className="d-flex align-items-center flex-column flex-md-row my-2">
                    <Typography variant="h3">Trace Product</Typography>
                </div>

                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <div className="d-flex mb-5">
                            <input type="text" className="form-control" placeholder="Asset ID" value={assetId} onChange={e => setAssetId(e.target.value)} />
                            <Button className="ms-2" onClick={handleTraceAsset} isLoading={isLoading}>Trace</Button>
                        </div>
                        {assetTrace && 
                            <>
                                <AssetTrace assetTrace={assetTrace} />
                            </>
                            
                        }
                    </div>
                </div>
                

                
            </div>
        </div>
    )
};

export default TraceTab;
