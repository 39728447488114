export const nodeData = [
    {
        nodeTitle: "Nemo id est",
        asset: "Ea est quae",
        createdBy: "Maynard Stoltenberg",
        createdByAvatar: "/images/avatar8.svg",
        nodeUsers: ["Jalon, ", "Patricia, ", "Craig"],
        nodeUsersAvatar: [
            "/images/avatar5.svg",
            "/images/avatar2.svg",
            "/images/avatar3.svg",
        ],
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        nodeTitle: "Facilis rerum dolor",
        asset: "Dolores ut rerum",
        nodeUsers: ["Erich, ", "Elen "],
        nodeUsersAvatar: ["/images/avatar4.svg", "/images/avatar5.svg"],
        createdBy: "Felipa Kemmer",
        createdByAvatar: "/images/avatar2.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        nodeTitle: "Deserunt eum pariatur",
        asset: "Doloribus eos sit",
        nodeUsers: ["Keira, ", "Dejuan ", "Verona "],
        nodeUsersAvatar: [
            "/images/avatar6.svg",
            "/images/avatar7.svg",
            "/images/avatar8.svg",
        ],
        createdBy: "Mallie Auer",
        createdByAvatar: "/images/avatar3.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        nodeTitle: "Quod illum magnam",
        asset: "Autem harum voluptas",
        nodeUsers: ["Rodger"],
        nodeUsersAvatar: ["/images/avatar2.svg"],
        createdBy: "Meredith Lesch",
        createdByAvatar: "/images/avatar4.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        nodeTitle: "Dicta quia labore",
        asset: "Esse dicta nulla",
        nodeUsers: ["Alfred, ", "Harold"],
        nodeUsersAvatar: ["/images/avatar3.svg", "/images/avatar4.svg"],
        createdBy: "David Ratke",
        createdByAvatar: "/images/avatar5.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        nodeTitle: "Laudantium vel explicabo",
        asset: "Repellat qui omnis",
        nodeUsers: ["Jalon, ", "Patricia, ", "Craig"],
        nodeUsersAvatar: [
            "/images/avatar5.svg",
            "/images/avatar7.svg",
            "/images/avatar8.svg",
        ],
        createdBy: "Percy Abbott",
        createdByAvatar: "/images/avatar6.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
];


export const assetData = [
    {
        node: "Nemo id est",
        assetTitle: "Ea est quae",
        createdBy: "Maynard Stoltenberg",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Facilis rerum dolor",
        assetTitle: "Dolores ut rerum",
        createdBy: "Felipa Kemmer",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Deserunt eum pariatur",
        assetTitle: "Doloribus eos sit",
        createdBy: "Mallie Auer",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Quod illum magnam",
        assetTitle: "Autem harum voluptas",
        createdBy: "Meredith Lesch",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Dicta quia labore",
        assetTitle: "Esse dicta nulla",
        createdBy: "David Ratke",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Laudantium vel explicabo",
        assetTitle: "Repellat qui omnis",
        createdBy: "Percy Abbott",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
];

export const tableData = [
    {
        node: "Nemo id est",
        asset: "Ea est quae",
        createdBy: "Maynard Stoltenberg",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Facilis rerum dolor",
        asset: "Dolores ut rerum",
        createdBy: "Felipa Kemmer",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Deserunt eum pariatur",
        asset: "Doloribus eos sit",
        createdBy: "Mallie Auer",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Quod illum magnam",
        asset: "Autem harum voluptas",
        createdBy: "Meredith Lesch",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Dicta quia labore",
        asset: "Esse dicta nulla",
        createdBy: "David Ratke",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
    {
        node: "Laudantium vel explicabo",
        asset: "Repellat qui omnis",
        createdBy: "Percy Abbott",
        createdByAvatar: "/images/avatar.svg",
        createdDate: "31st Dec 2022",
        action: "--",
    },
];



export const userData = [
    {
        id: '1',
        avatar: '/images/avatar.svg',
        name: 'John Doe',
        email: 'john.doe@example.com',
        role: 'Admin',
        status: 'Active',
        dateCreated: '2022-01-01',
    },
    {
        id: '2',
        avatar: '/images/avatar2.svg',
        name: 'Jane Doe',
        email: 'jane.doe@example.com',
        role: 'User',
        status: 'Inactive',
        dateCreated: '2022-01-02',
    },
    {
        id: '3',
        avatar: '/images/avatar3.svg',
        name: 'Alice Smith',
        email: 'alice.smith@example.com',
        role: 'Admin',
        status: 'Active',
        dateCreated: '2022-01-03',
    },
    {
        id: '4',
        avatar: '/images/avatar4.svg',
        name: 'Bob Johnson',
        email: 'bob.johnson@example.com',
        role: 'User',
        status: 'Pending',
        dateCreated: '2022-01-04',
    },
    {
        id: '5',
        avatar: '/images/avatar5.svg',
        name: 'Charlie Brown',
        email: 'charlie.brown@example.com',
        role: 'Admin',
        status: 'Active',
        dateCreated: '2022-01-05',
    },
    {
        id: '6',
        avatar: '/images/avatar6.svg',
        name: 'David Williams',
        email: 'david.williams@example.com',
        role: 'User',
        status: 'Inactive',
        dateCreated: '2022-01-06',
    },
    {
        id: '7',
        avatar: '/images/avatar7.svg',
        name: 'Eve Davis',
        email: 'eve.davis@example.com',
        role: 'Admin',
        status: 'Active',
        dateCreated: '2022-01-07',
    },
    {
        id: '8',
        avatar: '/images/avatar8.svg',
        name: 'Frank Miller',
        email: 'frank.miller@example.com',
        role: 'User',
        status: 'Pending',
        dateCreated: '2022-01-08',
    },
];