import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Button from "../../../../components/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import { Form } from "../../../../components/forms";
import ColorPicker from "../../../../components/forms/ColorPicker";
import ImageUpload from "../../../../components/forms/ImageUpload";
import { useUploadFileMutation } from "../../../../services/fileManagementService";
import {
    useConfigureCustomerPortalThemeSettingMutation,
    useGetCustomerPortalThemeSettingQuery,
} from "../../../../services/taasService";
import { logEvent } from "../../../../utils/amplitudeUtlis";

const ThemeSetting = ({ id }) => {
    // State
    const [files, setFiles] = useState([]);
    const [isUpdatingTheme, setIsUpdatingTheme] = useState(false);
    const [formData, setFormData] = useState({
        primary_color: "#808080",
        secondary_color: "#808080",
        nav_bar_color: "#808080",
        logo: null,
    });

    // Hooks
    const [uploadFile] = useUploadFileMutation({
        fixedCacheKey: "shared-upload-image",
    });
    const [configureTheme] = useConfigureCustomerPortalThemeSettingMutation();
    const { data: themeResponse } = useGetCustomerPortalThemeSettingQuery({
        projectId: id,
    });

    // Memoized logo image
    const logoImage = useMemo(
        () => themeResponse?.data[0]?.logo,
        [themeResponse]
    );

    // Effects
    useEffect(() => {
        if (themeResponse) {
            setFormData({ ...themeResponse.data[0] });
        }
    }, [themeResponse]);

    // validationSchema
    const validationSchema = Yup.object().shape({
        primary_color: Yup.string().required("Primary color is required"),
        secondary_color: Yup.string().required("Secondary color is required"),
        nav_bar_color: Yup.string().required("Nav bar color is required"),
        logo: Yup.mixed()
            .required("Logo is required")
            .test(
                "fileSize",
                "File too large",
                (value) =>
                    !value || !(value instanceof File) || value.size <= 2097152 // 2MB
            )
            .test(
                "fileFormat",
                "Unsupported Format",
                (value) =>
                    !value ||
                    !(value instanceof File) ||
                    ["image/jpg", "image/jpeg", "image/png"].includes(
                        value.type
                    )
            ),
    });

    // Handlers
    const handleUpdateDemoData = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleThemeSubmit = async (values) => {
        setIsUpdatingTheme(true);
        if (files) {
            try {
                const formData = new FormData();
                formData.append("file", files);
                const response = await uploadFile({ file: formData });

                if (response.error) {
                    throw new Error("Could not upload the image");
                }
                values.image = response?.data?.data?.id;
            } catch (error) {
                console.error("Image upload failed:", error);
                toast("Image upload failed", error);
            }
        }
        try {
            const configureThemeRes = await configureTheme({
                projectId: id,
                primary_color: values.primary_color,
                secondary_color: values.secondary_color,
                nav_bar_color: values.nav_bar_color,
                logo: values?.image ? values?.image : values?.logo?.id,
            });
            if (configureThemeRes?.data) {
                toast("Theme updated successfully");
                logEvent({
                    eventName: "theme_updated",
                    eventProperties: {
                        theme: values,
                    },
                });
            } else {
                toast("Theme update failed");
                logEvent({
                    eventName: "theme_update_failed",
                    eventProperties: {
                        theme: values,
                    },
                });
            }
        } catch (error) {
            console.error("Theme update failed:", error);
        } finally {
            setIsUpdatingTheme(false);
        }
    };

    // Render
    return (
        <div>
            <Typography variant="h3" className="py-3">
                Theme Settings
            </Typography>
            <Form
                initialValues={formData}
                onSubmit={handleThemeSubmit}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="d-flex flex-column">
                            <div className="">
                                <ColorPicker
                                    value={formData.primary_color}
                                    label="Primary color"
                                    name="primary_color"
                                    placeholder="Pick a color"
                                    onChange={(e) =>
                                        handleUpdateDemoData(
                                            "primary_color",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="pt-3">
                                <ColorPicker
                                    value={formData.secondary_color}
                                    label="Secondary color"
                                    name="secondary_color"
                                    placeholder="Pick a color"
                                    onChange={(e) =>
                                        handleUpdateDemoData(
                                            "secondary_color",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="pt-3">
                                <ColorPicker
                                    value={formData.nav_bar_color}
                                    label="Nav Bar color"
                                    name="nav_bar_color"
                                    placeholder="Pick a color"
                                    onChange={(e) =>
                                        handleUpdateDemoData(
                                            "nav_bar_color",
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="pt-3">
                                <div className="d-flex align-items-center gap-3">
                                    <ImageUpload
                                        name="logo"
                                        onFileSelected={setFiles}
                                        label="Brand Logo"
                                        logoImage={logoImage}
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                handleUpdateDemoData(
                                                    "logo",
                                                    file
                                                );
                                            }
                                        }}
                                        width="100%"
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                variant="secondary"
                                fullWidth={false}
                                className="mt-2 mt-md-3 text-overflow ms-auto"
                            >
                                {isUpdatingTheme ? (
                                    <div>
                                        <span
                                            className="spinner-border spinner-border-sm mx-2"
                                            aria-hidden="true"
                                        ></span>
                                        <span role="status">Loading...</span>
                                    </div>
                                ) : (
                                    <>Update Theme</>
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default ThemeSetting;
