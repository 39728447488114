import React from "react";
import "./card.scss";
import Typography from "../../../Typography/Typography";

export default function CustomerDashboardCard({ name, value, style, variant }) {
    return (
        <div
            className={`card w-100 customer-dashboard-card d-flex flex-column align-items-center justify-content-center`}
            style={style}
        >
            <div className="card-body m-2">
                <Typography
                    variant="h2"
                    className="m-0 number-text text-center"
                >
                    {value}
                </Typography>

                <Typography
                    variant="body2"
                    className="card-subtitle name-text text-center mt-2"
                >
                    {name}
                </Typography>
            </div>
        </div>
    );
}
