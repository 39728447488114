import React from "react";
import "./form.scss";

const ColorPicker = ({
    label,
    name,
    placeholder,
    value,
    onChange,
    ...props
}) => {
    return (
        <div className="w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <label
                className="color-picker w-100"
                htmlFor={`${name}-color-picker`}
            >
                <input
                    type="color"
                    className="color-input"
                    id={`${name}-color-picker`}
                    value={value}
                    onChange={onChange}
                    {...props}
                />
                <input
                    value={value}
                    type="text"
                    className="text-input"
                    id={name}
                    placeholder={placeholder || ""}
                    onChange={onChange}
                    disabled
                />
            </label>
        </div>
    );
};

export default ColorPicker;
