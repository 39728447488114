export function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.abs((now - date) / 1000);
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;

    if (diffInDays >= 1) return Math.floor(diffInDays) + " days ago";
    if (diffInHours >= 1) return Math.floor(diffInHours) + " hours ago";
    if (diffInMinutes >= 1) return Math.floor(diffInMinutes) + " minutes ago";
    return "Just now";
}
