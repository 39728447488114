import React from "react";
import { Icon } from "../../../../components/Icon";
import { BasicTable } from "../../../../components/tables";
import { useNavigate } from "react-router-dom";

export default function AssetTab({ assetData }) {
    const navigate = useNavigate();

    const handleEditClick = (assetId) => {
        navigate(`asset-type/${assetId}/edit/`);
    };

    return (
        <div className="mx-2">
            <BasicTable
                data={assetData}
                columns={[
                    {
                        header: "Asset Title",
                        accessor: "type_name",
                    },
                    // {
                    //     header: "Created by",
                    //     accessor: "created_by",
                    //     Cell: ({ row }) => (
                    //         <div className="d-flex align-items-center">
                    //             {/* <img
                    //                 src={row.original.createdByAvatar}
                    //                 alt={row.original.createdBy}
                    //                 className="m-2 rounded-circle me-2"
                    //                 style={{
                    //                     width: "35px",
                    //                     height: "35px",
                    //                 }}
                    //             /> */}
                    //             {row.original.created_by}
                    //         </div>
                    //     ),
                    // },
                    {
                        header: "Created date",
                        accessor: "created_at",
                        Cell: ({ cell: { value } }) => {
                            const dateObject = new Date(value);
                            const formattedDate = dateObject
                                .toLocaleDateString("en-US")
                                .split("/")
                                .join("-");
                            return <div>{formattedDate}</div>;
                        },
                    },
                    {
                        header: "",
                        accessor: "action",
                        Cell: ({ row }) => (
                            <div className="d-flex flex-row justify-content-end">
                                <Icon
                                    src="/icons/edit.svg"
                                    className="mx-2"
                                    height="15px"
                                    width="15px"
                                    onClick={() => {
                                        handleEditClick(row.original.id);
                                    }}
                                />
                            </div>
                        ),
                    },
                ]}
                isSortable={false}
                isPaginated={assetData?.length > 8}
                pageSize={8}
                currentPage={0}
            />
        </div>
    );
}
