import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueries';


export const taasAPI = createApi({
    reducerPath: 'taasAPI',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Tokenization', 'Asset', 'Payment'],
    endpoints: (builder) => ({
        getTokenization: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/`,
            providesTags: ['Asset', 'Tokenization']
        }),
        getAsset: builder.query({
            query: ({ projectId, assetId }) => `/api/templates/tokenization/${projectId}/assets/${assetId}/`,
            providesTags: ['Asset', 'Tokenization']
        }),
        createAsset: builder.mutation({
            query: ({ projectId, name, symbol, price, supply, short_description, description, image }) => ({
                url: `/api/templates/tokenization/${projectId}/assets/`,
                method: 'POST',
                body: {
                    name,
                    symbol,
                    price,
                    supply,
                    short_description,
                    description,
                    image
                },
            }),
            invalidatesTags: ['Tokenization', 'Asset']
        }),
        updateAsset: builder.mutation({
            query: ({ projectId, assetId, name, symbol, short_description, description, image }) => ({
                url: `/api/templates/tokenization/${projectId}/assets/${assetId}/`,
                method: 'PUT',
                body: {
                    name,
                    symbol,
                    short_description,
                    description,
                    image
                },
            }),
            invalidatesTags: ['Asset']
        }),
        getPublicTokenization: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/public/`,
            providesTags: ['Asset']
        }),
        getPublicAsset: builder.query({
            query: ({ projectId, assetId }) => `/api/templates/tokenization/${projectId}/assets/${assetId}/public/`,
            providesTags: ['Asset']
        }),
        //strip account related apis 
        createExternalLogin: builder.mutation({
            query: ({ stripe_account_id }) => ({
                url: `api/stripe/connected-accounts/${stripe_account_id}/dashboard/`,
                method: 'POST',
            }),
            invalidatesTags: ['Payment']
        }),
        getExternalLogin: builder.query({
            query: (stripe_account_id) => ({
                url: `api/stripe/connected-accounts/${stripe_account_id}/dashboard/`,
                method: 'GET',
            }),
            providesTags: ['Payment']
        }),
        createStripeAccount: builder.mutation({
            query: () => ({
                url: `api/stripe/connected-accounts/account/`,
                method: 'POST',
            }),
            invalidatesTags: ['Payment']
        }),
        getConnectedAccounts: builder.query({
            query: () => ({
                url: '/api/stripe/connected-accounts/',
                method: 'GET',
            }),
            providesTags: ['Payment']
        }),
        updateDefaultStripeAccount: builder.mutation({
            query: ({ stripe_account, projectId }) => ({
                url: `/api/templates/tokenization/${projectId}/`,
                method: 'PUT',
                body: {
                    stripe_account
                },
            }),
            invalidatesTags: ['Payment']
        }),
        //Buy Token
        createClientSecret: builder.mutation({
            query: ({ projectId, is_ui_embedded }) => ({
                url: `/api/templates/tokenization/${projectId}/checkout/`,
                method: 'POST',
                body: {
                    is_ui_embedded
                }
            }),
            invalidatesTags: ['Payment', 'Tokenization']
        }),
        //Project Go live 
        createProjectGoLive: builder.mutation({
            query: ({ projectId }) => ({
                url: `/api/templates/tokenization/${projectId}/publish/`,
                method: 'POST'
            }),
            invalidatesTags: ['Tokenization']
        }),

        stopProjectGoLive: builder.mutation({
            query: ({ projectId }) => ({
                url: `/api/templates/tokenization/${projectId}/unpublish/`,
                method: 'POST'

            }),
            invalidatesTags: ['Tokenization']
        }),

        //Dashboard
        //Get API to get the project dashboard details page
        getProjectDashboard: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/dashboard/`,
            providesTags: ['Tokenization']
        }),

        //Get API to get the backers of the project 
        getProjectBackers: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/backers/`,
            providesTags: ['Tokenization']
        }),

        //POST api to configure theme for the taas project
        configureCustomerPortalThemeSetting: builder.mutation({
            query: ({ primary_color, secondary_color, nav_bar_color, logo, projectId }) => ({
                url: `/api/templates/tokenization/${projectId}/customer_portal/`,
                method: 'POST',
                body: {
                    primary_color,
                    secondary_color,
                    nav_bar_color,
                    logo
                }
            }),
            invalidatesTags: ['Tokenization']
        }),

        //GET api to get the customer portal theme settings
        getCustomerPortalThemeSetting: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/customer_portal/`,
            providesTags: ['Tokenization']
        }),
        //Get purchase record for the customer portal 
        getPurchaseRecord: builder.query({
            query: ({ projectId }) => `/api/templates/tokenization/${projectId}/purchase-records/`,
            providesTags: ['Tokenization']
        }),
        getPaymentRecordDetails: builder.query({

            query: ({ projectId, paymentId }) => `/api/templates/tokenization/${projectId}/payment-record/${paymentId}/`,
            providesTags: ['Tokenization']
        }),
    }),
});

export const {
    useGetTokenizationQuery,
    useGetAssetQuery, useCreateAssetMutation, useUpdateAssetMutation,
    useGetPublicTokenizationQuery, useGetPublicAssetQuery, useGetExternalLoginQuery, useCreateExternalLoginMutation, useCreateStripeAccountMutation, useGetConnectedAccountsQuery,
    useUpdateDefaultStripeAccountMutation, useCreateClientSecretMutation, useCreateProjectGoLiveMutation, useGetProjectDashboardQuery, useGetProjectBackersQuery, useStopProjectGoLiveMutation, useConfigureCustomerPortalThemeSettingMutation, useGetCustomerPortalThemeSettingQuery, useGetPurchaseRecordQuery, useGetPaymentRecordDetailsQuery
} = taasAPI;