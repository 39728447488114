import { createSlice } from '@reduxjs/toolkit';

// Define initial state with loading and error handling 
const initialState = { 
    projectDetails: null, 
    loading: false, 
    error: null, 
};

export const projectDetailsSlice = createSlice({ 
    name: 'projectDetails', 
    initialState, 
    reducers: { 
        setProjectDetails: (state, action) => { 
            state.projectDetails = action.payload; 
            state.loading = false; 
            state.error = null; 
        }, 
    }, 
});

export const { setProjectDetails } = projectDetailsSlice.actions;
export default projectDetailsSlice.reducer;