import { useModal } from "../../../../hooks/modalsHooks";
import Typography from "../../../Typography/Typography";
import "./model.scss";
import { useNavigate } from "react-router-dom";
import ModalLayout from "../../../modals/ModalLayout";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import { CustomerTokenCheckout } from "../../../../pages/taas/Customer/CustomerPurchase/CustomerTokenPurchase";
/**
 * Example modal component. Shown via `ModalContext`
 * @param {{
 * title: string
 * }} props
 * @returns {React.FunctionComponent}
 */
export default function CheckoutModal({
    title = "Add new project",
    projectId = "",
}) {
    const { hideModal } = useModal();
    const navigate = useNavigate();

    const handleClose = () => {
        hideModal();
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Checkout Modal Page",
            },
        });
    };

    const handleSuccess = () => {
        navigate(`/token-app/${projectId}`);
        hideModal();
        logEvent({
            eventType: "taas_buy_token_checkout_success",
            eventProperties: {
                projectId: projectId,
            },
        });
    };

    return (
        <ModalLayout width="100%" className="overflow-auto">
            <div className="card d-flex justify-content-center p-4">
                <ModalLayout.Header>
                    <div className="text-center">
                        <Typography variant="h2">Purchase token</Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="row d-flex flex-wrap mt-2">
                        <CustomerTokenCheckout
                            onSuccess={handleSuccess}
                            projectId={projectId}
                        />
                    </div>
                </ModalLayout.Body>
                <ModalLayout.Footer>
                    <div className="mt-4 d-flex justify-content-center flex-row align-items-center">
                        <button
                            type="button"
                            className="d-flex align-items-center btn btn-secondary me-2"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalLayout.Footer>
            </div>
        </ModalLayout>
    );
}
