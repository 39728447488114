import React, { useState, useMemo } from "react";
import Button from "../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../components/Typography/Typography";
import { useSelector } from "react-redux";
import { TextField, Form, Select } from "../../../components/forms";
import {
    useGetAssetsListQuery,
    useGetNodesListQuery,
} from "../../../services/supplyChainService";
import * as yup from "yup";
import { useAddTransferMutation } from "../../../services/supplyChainService";
import { toast } from "react-toastify";
import { useSDK } from "@thirdweb-dev/react";
import { useGetProjectDetailQuery } from "../../../services/projectService";
import { logEvent } from "../../../utils/amplitudeUtlis";

function AddTransferPage() {
    const sdk = useSDK();
    const { id: projectId } = useParams();
    const {
        data: project,
        error,
        isLoading: isLoadingProject,
    } = useGetProjectDetailQuery(projectId);

    const navigate = useNavigate();

    const { data: nodesResponse, isLoading: isLoadingNodes } =
        useGetNodesListQuery(projectId);

    const [assets, setAssets] = useState([{ asset_id: "", qty: "" }]);
    const [addTransfer] = useAddTransferMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [fromNode, setFromNode] = useState("");
    const [toNode, setToNode] = useState("");

    const { data: assetTypesResponse, isLoading: isLoadingAssetTypes } =
        useGetAssetsListQuery({ projectId, nodeId: fromNode });

    const [formValues, setFormValues] = useState({
        fromNode: "",
        toNode: "",
        assets: [{ asset_id: "", qty: 100 }],
    });

    const assetTypeList = useMemo(() => {
        return assetTypesResponse?.data.map((assetType) => ({
            id: assetType.uuid,
            label: assetType.asset_id,
            value: assetType.uuid,
        }));
    }, [assetTypesResponse]);

    const nodeList = useMemo(() => {
        return nodesResponse?.data.map((node) => ({
            label: node.name,
            value: node.id,
        }));
    }, [nodesResponse]);

    const validationSchema = yup.object().shape({
        fromNode: yup.string().required("From Node is required"),
        toNode: yup.string().required("To Node is required"),
        assets: yup.array().of(
            yup.object().shape({
                asset_id: yup.string().required("Asset is required"),
                qty: yup.string().required("Quantity is required"),
            })
        ),
    });

    const handleCloseClick = () => {
        // Go back
        navigate(-1);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Add Transfer Page",
            },
        });
    };

    const filterNodeOptions = (currentNode) => {
        const otherNode = currentNode === "fromNode" ? toNode : fromNode;
        return nodeList?.filter((option) => option.value !== otherNode);
    };

    const handleFromNodeChange = (event) => {
        setFromNode(event.target.value);

        setFormValues((prevValues) => ({
            ...prevValues,
            fromNode: event.target.value,
        }));
        console.log("formValues", formValues);
    };

    const handleToNodeChange = (event) => {
        setToNode(event.target.value);

        setFormValues((prevValues) => ({
            ...prevValues,
            toNode: event.target.value,
        }));
    };
    const handleOnChangeAssetProp = (index, prop, value) => {
        const newAssets = [...assets];
        newAssets[index][prop] = value;
        setAssets(newAssets);

        const newFormValues = { ...formValues };
        newFormValues.assets[index][prop] = value;
        setFormValues(newFormValues);
    };

    const addAsset = () => {
        setAssets([...assets, { asset_id: "", qty: "" }]);
        const newFormValues = { ...formValues };
        newFormValues.assets.push({ asset_id: "", qty: "" });
        setFormValues(newFormValues);
    };

    const isFormValid = () => {
        // Check if all fields in formValues are filled out
        for (let key in formValues) {
            if (formValues[key] === "") {
                return false;
            }
        }

        // Check if all assets have both asset_id and qty filled out
        for (let asset of formValues.assets) {
            if (asset.asset_id === "") {
                return false;
            }
        }

        return true;
    };

    const removeAsset = (index) => {
        const newAssets = [...assets];
        newAssets.splice(index, 1);
        setAssets(newAssets);

        const newFormValues = { ...formValues };
        newFormValues.assets.splice(index, 1);
        setFormValues(newFormValues);
    };

    const filterOptions = (index) => {
        const selectedValues = assets
            .filter((_, i) => i !== index)
            .map((asset) => asset.asset_id);
        return assetTypeList?.filter(
            (option) => !selectedValues.includes(option.value)
        );
    };

    const handleAddNodeSubmit = async () => {
        logEvent({
            eventName: "transfer_creation_initiated",
            eventProperties: {
                page_name: "Add Transfer Page",
            },
        });
        setIsLoading(true);
        try {
            const addTransferResponse = await addTransfer({
                id: projectId,
                receiver: formValues.receiver,
                from_node: formValues.fromNode,
                to_node: formValues.toNode,
                transfer_content: formValues.assets,
            });

            if (addTransferResponse?.error) {
                navigate(`/overview/${projectId}/transfer`);
                throw new Error("Error in creating Transfer.");
            }

            const instructions = addTransferResponse.data.data.instructions;
            console.log("Transfer Assets Instructions: ", instructions);
            for (var instruction of instructions) {
                const contract = await sdk.getContractFromAbi(
                    instruction.contract,
                    instruction.abi
                );
                const txn = await contract.call(
                    instruction.command,
                    instruction.args,
                    {
                        gasLimit: 100000000,
                    }
                );
                console.log("Debug:", instruction.command, txn);
            }

            toast("Transferred successfully.");
            logEvent({
                eventName: "transfer_creation_completed",
                eventProperties: {
                    transfer_id: addTransferResponse.data.data.id,
                    project_id: projectId,
                },
            });
            navigate(`/overview/${projectId}/transfer`);
        } catch {
            logEvent({
                eventName: "transfer_creation_failed",
                eventProperties: {
                    error: error.message,
                },
            });
            toast.error("Error with transfer data");
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoadingAssetTypes || isLoadingNodes || isLoadingProject) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <div className="spinner-grow" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="h-100 min-vh-100 w-100 m-0 px-4">
            <div className="mt-3">
                <Typography variant="body1">
                    Overview / {project?.data?.name} / Add Transfer
                </Typography>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <Typography variant="h1">Add new Transfer</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="divider-line mt-4"></div>
            </div>
            <div className="container col-xxl-8 px-4 mt-5">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 mx-auto justify-content-center">
                        <Typography variant="h2">Transfer Details</Typography>
                        <div className="mx-3 mt-1 row d-flex flex-wrap">
                            <Form
                                initialValues={formValues}
                                // validationSchema={validationSchema}
                                onSubmit={handleAddNodeSubmit}
                            >
                                <div className="w-100 mt-3">
                                    <Select
                                        name="fromNode"
                                        label="From Node"
                                        options={filterNodeOptions("fromNode")}
                                        onChange={handleFromNodeChange} // Add this line
                                        value={fromNode} // Add this line
                                    />
                                </div>
                                <div className="w-100 mt-3">
                                    <Select
                                        name="toNode"
                                        label="To Node"
                                        options={filterNodeOptions("toNode")}
                                        onChange={handleToNodeChange}
                                        value={toNode}
                                    />
                                </div>
                                {assets.map((asset, index) => (
                                    <div key={index} className="add-container">
                                        <div className="d-flex justify-content-between m-3 add-header">
                                            <div className="add-title">
                                                Asset # {index + 1}
                                            </div>
                                            <div
                                                className="add-close"
                                                onClick={() =>
                                                    removeAsset(index)
                                                }
                                            >
                                                X Remove
                                            </div>
                                        </div>
                                        <div>
                                            <div className="m-3">
                                                <Select
                                                    name={`asset${index}`} // Use index to make names unique
                                                    label="Assets"
                                                    options={filterOptions(
                                                        index
                                                    )} // Pass the current index
                                                    value={asset?.asset_id}
                                                    onChange={(event) =>
                                                        handleOnChangeAssetProp(
                                                            index,
                                                            "asset_id",
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="m-3">
                                                <TextField
                                                    name={`quantity${index}`} // Use index to make names unique
                                                    label="Assets Quantity"
                                                    value={100} // Set default value to 100
                                                    onChange={(event) =>
                                                        handleOnChangeAssetProp(
                                                            index,
                                                            "qty",
                                                            event.target.value
                                                        )
                                                    }
                                                    disabled // Disable the TextField
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div
                                    className="m-2 mb-4 add-another-node"
                                    onClick={addAsset}
                                >
                                    + Add another Asset
                                </div>
                                <div className="mt-3">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        fullWidth={true}
                                        isLoading={isLoading}
                                        disabled={!isFormValid()} // Add this line
                                    >
                                        Add Transfer
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTransferPage;
