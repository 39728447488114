import { useEffect } from "react";
import "./projectDashboard.scss";
import Typography from "../../../../components/Typography/Typography";
import { useGetTaasProjectsQuery } from "../../../../services/projectService";
import { useModal } from "../../../../hooks/modalsHooks";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import TaasSummary from "./TaasSummary";
import ProjectList from "./ProjectList";

export default function ProjectDashboardPage() {
    const { data: projects, isLoading, refetch } = useGetTaasProjectsQuery();
    const data = projects?.data;
    const { showModal } = useModal();
    const navigate = useNavigate();
    const handleCreateNewProject = async () => {
        logEvent({
            eventName: "project_creation_initiated",
            eventProperties: {},
        });
        showModal({
            modalType: "CreateProjectModal",
            modalProps: {
                projectType: "token-service",
            },
        });
    };

    const handleOnViewProjectLinkClick = (id) => {
        logEvent({
            eventName: "project_view_button_clicked",
            eventProperties: {
                project_name: data?.name,
                project_type: data?.template,
            },
        });
        navigate(`/overview/${id}/configuration`);
    };
    const tableData = data?.projects.map((project) => ({
        id: project.project_id,
        project:
            project.project_name.charAt(0).toUpperCase() +
            project.project_name.slice(1).toLowerCase(),
        backer: project.project_backers.toString(),
        amount: `$${project.project_funding}`,
        status: project.status
            .replace("_", " ")
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" "),
    }));

    useEffect(() => {
        refetch();
    }, [refetch]);

    if (isLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="container px-5 pt-2">
            <Typography variant="h2" className="pt-2">
                Dashboard
            </Typography>
            <TaasSummary data={data} />
            <ProjectList
                isLoading={isLoading}
                tableData={tableData}
                handleOnViewProjectLinkClick={handleOnViewProjectLinkClick}
                handleCreateNewProject={handleCreateNewProject}
            />
        </div>
    );
}
