import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../../../components/LoadingSkeleton/Loading";
import Typography from "../../../../components/Typography/Typography";
import { useGetPurchaseRecordQuery } from "../../../../services/taasService";
import { useProfileQuery } from "../../../../services/userService";
import Tab from "../../../../components/taas/customer/Tab/Tab";
import TabComponent from "./TabComponent";
import "./customerDashboard.scss";
import Button from "../../../../components/Button/Button";
import CustomerDashboardCard from "../../../../components/taas/customer/Card/CustomerDashboardCard";

export default function CustomerDashboardPage() {
    const { projectId } = useParams();

    const { data: purchaseRecordResponse, isLoading: isLoadingPurchaseRecord } =
        useGetPurchaseRecordQuery({ projectId });

    // const [pageData, setPageData] = useState({
    //     tokenCount: 0,
    // });
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    const { data: profileData, isLoading: isLoadingProfile } = useProfileQuery(
        {},
        {
            skip: !isLoggedIn,
        }
    );

    const tabsData = [
        {
            path: "Succeeded",
            name: "Succeeded",
            component: (
                <TabComponent
                    status="succeeded"
                    purchaseRecordResponse={purchaseRecordResponse?.data}
                />
            ),
        },
        {
            path: "pending",
            name: "Pending",
            component: (
                <TabComponent
                    status="pending"
                    purchaseRecordResponse={purchaseRecordResponse?.data}
                />
            ),
        },
        {
            path: "error",
            name: "Error",
            component: (
                <TabComponent
                    status="error"
                    purchaseRecordResponse={purchaseRecordResponse?.data}
                />
            ),
        },
    ];

    // useEffect(() => {
    //     loadData();
    // }, []);

    // const loadData = async () => {
    //     setPageData({
    //         ...pageData,
    //         tokenCount: 1,
    //     });
    // };

    return isLoadingPurchaseRecord || isLoadingProfile ? (
        <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100vh" }}
        >
            <Loading />
        </div>
    ) : (
        <div className="container p-5">
            <div className="row d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-3 justify-content-md-start pt-5">
                <div className="col text-center text-md-start">
                    <Typography variant="h1">
                        Welcome back,{" "}
                        {profileData?.data?.username.split("@")[0]}
                    </Typography>
                </div>
                <div className="col d-flex align-items-center justify-content-center justify-content-md-end">
                    <Button
                        type="button"
                        variant="secondary"
                        fullWidth={false}
                        app="customer"
                    >
                        Manage your account
                    </Button>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                    <CustomerDashboardCard
                        name="Total funding"
                        value={`$${
                            purchaseRecordResponse?.data
                                ?.total_investment_value || 0
                        }`}
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                    <CustomerDashboardCard
                        name="Project Backed"
                        value={
                            purchaseRecordResponse?.data?.projects_backed || 0
                        }
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4 d-flex flex-wrap pb-3 pb-md-0">
                    <CustomerDashboardCard
                        name="Perks Received"
                        value={purchaseRecordResponse?.data?.perks || 0}
                    />
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12 d-flex flex-wrap ">
                    <Typography variant="h3" className="m-0">
                        Member since August 2022
                    </Typography>
                </div>
                <div className="col-12 pt-4">
                    <div className="card customer-dashboard-card w-100">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <Typography
                                        variant="h4"
                                        // className="card-title"
                                    >
                                        Get early access to new features
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        // className="card-text"
                                    >
                                        Try out our latest tools, like the
                                        ability to follow creators and save
                                        projects for later.
                                    </Typography>
                                </div>
                                <Button
                                    variant="primary text-overflow"
                                    fullWidth={false}
                                    app="customer"
                                >
                                    Join Beta Program
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-12 d-flex flex-wrap ">
                    <Typography variant="h3" className="m-0">
                        Yours Activity
                    </Typography>
                </div>
                <div className="col-12 pt-3">
                    <Tab
                        tabsData={tabsData}
                        tabType="nav-tabs"
                        defaultTab={0}
                    />
                </div>
            </div>
        </div>
    );
}
